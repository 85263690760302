import {Button} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../stores/AppStore';
import DomainSuggestion from '../../../stores/Domain/DomainSuggestion';
import {Plan} from '../../../stores/Plan';
import BalanceTitle from '../BalanceTitle';
import {OrderInstance} from '../PaymentViewer';
import RenewDomainSummary from '../RenewDomainSummary';
import PayPlanButtonWithHoursLeft from './PayPlanButtonWithHoursLeft';

interface IProps {
  orderInstance?: OrderInstance;
  onSwitchTopUpBalance?(): void;
  onClickPayButton?(): Promise<void>;
  renewDomain?: boolean;
}

export const PayWithoutTopUpForm: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const {billingStore} = useStore();

  const [loading, setLoading] = React.useState(false);

  const handleClickPayButton = async () => {
    setLoading(true);
    await props.onClickPayButton?.();
    setLoading(false);
  };

  return (
    <>
      <BalanceTitle balanceFormatted={billingStore.balanceFormatter.formatted} />

      <Button
        sx={(theme) => ({margin: `${theme.spacing(4)} 0`})}
        variant="outlined"
        size="large"
        fullWidth
        onClick={props.onSwitchTopUpBalance}
      >
        {t('component_payment_viewer_top_up_balance_button')}
      </Button>

      {props.orderInstance instanceof DomainSuggestion && props.renewDomain ? (
        <RenewDomainSummary domainSuggestion={props.orderInstance} />
      ) : null}

      {props.orderInstance instanceof Plan ? (
        <PayPlanButtonWithHoursLeft
          plan={props.orderInstance}
          loading={loading}
          onClickPayButton={handleClickPayButton}
        />
      ) : (
        <Button variant="contained" fullWidth size="large" onClick={handleClickPayButton}>
          {props.renewDomain
            ? t('component_payment_viewer_pay_and_renew_domain_button', {
                amount: props.orderInstance?.renewalPriceFormatter.formatted,
              })
            : t('component_payment_viewer_pay_and_order_domain_button', {
                amount: props.orderInstance?.registrationPriceFormatter.formatted,
              })}
        </Button>
      )}
    </>
  );
});

export default PayWithoutTopUpForm;
