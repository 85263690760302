import {action, computed, makeObservable, observable} from 'mobx';
import {ca2billing, ca2servers, ca2types} from '../../api/proto';
import {ZERO_AMOUNT} from '../../constants';
import PriceFormatter from '../../utils/priceFormatter';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';
import {BalanceOperationsStore} from './BalanceOperationsStore';
import Currency from './Currency';

const DEFAULT_PAYMENT_SYSTEM = ca2types.PaymentSystem.PS_COINS_PAID;

export class BillingStore extends APILayer {
  balanceOperations: BalanceOperationsStore = new BalanceOperationsStore(this);

  @observable isInit: boolean = false;

  @observable paymentSystem: ca2types.PaymentSystem = DEFAULT_PAYMENT_SYSTEM;
  @observable currencies: Currency[] = [];

  @observable hoursLeft: number = 0;

  @observable balanceFormatter: PriceFormatter = new PriceFormatter(ZERO_AMOUNT);
  @observable bonusBalanceFormatter: PriceFormatter = new PriceFormatter(ZERO_AMOUNT);
  @observable chargePerHourFormatter: PriceFormatter = new PriceFormatter(ZERO_AMOUNT);
  @observable chargePerDayFormatter: PriceFormatter = new PriceFormatter(ZERO_AMOUNT);
  @observable chargePerMonthFormatter: PriceFormatter = new PriceFormatter(ZERO_AMOUNT);

  @observable isInitBillingPage: boolean = true;

  @observable paymentCurrencyCode = 'BTC';

  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);

    this.app.wsApi.on('billing.balances', this.onUpdateBalances_);
  }

  @computed get paymentCurrency() {
    return this.findPaymentCurrency(this.paymentCurrencyCode);
  }

  @action setPaymentCurrencyCode = (code: string) => {
    this.paymentCurrencyCode = code;
  };

  @action findPaymentCurrency = (code: string) => {
    return this.currencies.find((cur) => code === cur.code);
  };

  @action private setBalances_ = (balances: ca2types.IBalance[]) => {
    for (const {type, amount} of balances) {
      switch (type) {
        case ca2types.BalanceType.BT_MAIN:
          if (amount) {
            this.balanceFormatter = new PriceFormatter(amount);
          }
          break;
        case ca2types.BalanceType.BT_BONUS:
          if (amount) {
            this.bonusBalanceFormatter = new PriceFormatter(amount);
          }
          break;
      }
    }
  };

  @action resetBillingPageState = () => {
    this.isInitBillingPage = false;

    this.chargePerHourFormatter = new PriceFormatter(ZERO_AMOUNT);
    this.chargePerDayFormatter = new PriceFormatter(ZERO_AMOUNT);
    this.chargePerMonthFormatter = new PriceFormatter(ZERO_AMOUNT);
    this.hoursLeft = 0;
  };

  initBillingPageState = async () => {
    if (this.isInitBillingPage || !this.isInit) {
      return;
    }

    await this.loadPaymentOptions_();
    await this.balanceOperations.init();

    this.isInitBillingPage = true;
  };

  init = async () => {
    if (this.isInit) {
      return;
    }

    await this.initialLoad_();
    await this.loadPaymentOptions_();
    await this.balanceOperations.init();

    this.isInit = true;
  };

  private initialLoad_ = async () => {
    const {res} = await this.request({
      billing: {
        balances: {},
        currencies: {
          paymentSystem: this.paymentSystem,
        },
      },
    });

    if (res?.billing?.balances) {
      this.loadBalancesProcess_(res.billing.balances);
    }

    if (res?.billing?.currencies) {
      this.processLoadCurrencies_(res.billing.currencies);
    }
  };

  @action private loadBalancesProcess_ = (res: ca2billing.IBalancesResponse) => {
    if (res.items) {
      this.setBalances_(res.items);
    }
  };

  @action private processLoadCurrencies_ = (res: ca2billing.ICurrenciesResponse) => {
    if (res.items) {
      this.currencies = res.items.map((raw) => new Currency(raw));
    }
  };

  topUpBalance = async ({amountUsd, currencyCode}: {amountUsd: number; currencyCode?: string | null}) => {
    const {res, error} = await this.request({
      billing: {
        topUpBalance: {
          paymentSystem: this.paymentSystem,
          amountUsd,
          currencyCode,
        },
      },
    });

    return {error, res: res?.billing?.topUpBalance};
  };

  subscribeToPayments(callback: (payments: ca2types.IPayment[] | null) => void) {
    this.app.wsApi.on('billing.payments', callback);
  }

  unsubscribeFromPayments(callback: (payments: ca2types.IPayment[] | null) => void) {
    this.app.wsApi.off('billing.payments', callback);
  }

  private onUpdateBalances_ = (balances: ca2types.IBalance[]) => {
    this.setBalances_(balances);
  };

  private loadPaymentOptions_ = async () => {
    this.setLoading(true);

    const {res} = await this.request({
      servers: {
        paymentOptions: {},
      },
    });

    if (res?.servers?.paymentOptions) {
      this.processLoadPaymentOptions_(res?.servers?.paymentOptions);
    }

    this.setLoading(false);
  };

  @action processLoadPaymentOptions_ = ({
    chargePerMonth,
    chargePerDay,
    chargePerHour,
    hoursLeft,
  }: ca2servers.IPaymentOptionsResponse) => {
    if (chargePerMonth) {
      this.chargePerMonthFormatter = new PriceFormatter(chargePerMonth);
    }

    if (chargePerDay) {
      this.chargePerDayFormatter = new PriceFormatter(chargePerDay);
    }

    if (chargePerHour) {
      this.chargePerHourFormatter = new PriceFormatter(chargePerHour);
    }

    this.hoursLeft = hoursLeft || 0;
  };

  @action reset = () => {
    this.isInit = false;
    this.balanceFormatter = new PriceFormatter(ZERO_AMOUNT);
    this.bonusBalanceFormatter = new PriceFormatter(ZERO_AMOUNT);
    this.paymentSystem = DEFAULT_PAYMENT_SYSTEM;
    this.currencies = [];

    this.chargePerHourFormatter = new PriceFormatter(ZERO_AMOUNT);
    this.chargePerDayFormatter = new PriceFormatter(ZERO_AMOUNT);
    this.chargePerMonthFormatter = new PriceFormatter(ZERO_AMOUNT);
    this.hoursLeft = 0;

    this.balanceOperations.reset();
  };
}

export default BillingStore;
