import {Box, Button, styled, Typography} from '@mui/material';
import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DomainsIcon} from '../../../../assets/icons/domains.svg';
import {ReactComponent as ErrorIcon} from '../../../../assets/icons/error-square.svg';
import {ReactComponent as SuccessIcon} from '../../../../assets/icons/success-square.svg';
import DomainSuggestion from '../../../../stores/Domain/DomainSuggestion';
import PremiumDomainLabel, {IPremiumDomainLabelRef} from './PremiumDomainLabel';

const Container = styled(Box)(({theme}) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '12px',
  backgroundColor: theme.palette.backgroundPrimary,
  marginBottom: theme.spacing(2),
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  overflow: 'hidden',
  minHeight: 65,

  '&:not(.isReady):before': {
    position: 'absolute',
    content: '""',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    background:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(90deg, #242424 0%, #414141 100%)'
        : 'linear-gradient(90deg, #F5F5F5 0%, #DBDBDB 100%)',
    backgroundSize: '200% 200%',
    animation: 'gradient-move 1s infinite alternate',
    pointerEvents: 'none',

    '@keyframes gradient-move': {
      '0%': {
        backgroundPosition: '0% 50%',
      },
      '100%': {
        backgroundPosition: '100% 50%',
      },
    },
  },

  '&.isReady:not(.isAvailable)': {
    backgroundColor: theme.palette.backgroundSecondary,
  },

  '&.isAvailable': {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.backgroundSecondary,
    },
  },

  '&.isAvailable.existWithSearchText': {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(4)}`,
    '& .rightBox': {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      border: `1px solid ${theme.palette.border.primary}`,
      borderRadius: '8px',
    },
  },

  '& .rightBox': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },

  '& .domainName': {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: theme.spacing(2),
    },
  },

  '& .domainPrice': {
    lineHeight: 1,
    marginRight: theme.spacing(3),
  },

  '& .label': {
    display: 'inline-block',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    marginLeft: theme.spacing(3),
    borderRadius: '8px',

    '&--taken': {
      backgroundColor: theme.palette.error.alpha1,
      color: theme.palette.error.primary,
    },

    '&--discount': {
      backgroundColor: theme.palette.success.alpha1,
      color: theme.palette.success.primary,
    },
  },

  '& .MuiTypography-root': {
    lineHeight: 1,
  },
}));

function calcDiscountPercent(oldPrice: number, newPrice: number): number | null {
  if (oldPrice === 0) {
    return null;
  }
  const change = oldPrice - newPrice;
  const percentageChange = (change / oldPrice) * 100;
  return +percentageChange.toFixed(2);
}

interface IProps {
  domainSuggestion: DomainSuggestion;
  onRegisterDomain?(suggestion: DomainSuggestion): void;
}

export const DomainSuggestionItem: React.FC<IProps> = observer(({domainSuggestion, onRegisterDomain}) => {
  const {t} = useTranslation();

  const premiumDomainLabelRef = React.useRef<IPremiumDomainLabelRef>(null);

  const {isReady, isAvailable, isPremium, whoisUrl, existWithSearchText} = domainSuggestion;

  const hasOldRegistrationPrice = !!domainSuggestion.oldRegistrationPriceFormatter.points;

  const discountPercent = React.useMemo(() => {
    return calcDiscountPercent(
      domainSuggestion.oldRegistrationPriceFormatter.points,
      domainSuggestion.registrationPriceFormatter.points,
    );
  }, [domainSuggestion.oldRegistrationPriceFormatter.points, domainSuggestion.registrationPriceFormatter.points]);

  const handleClickOrderButton = () => {
    onRegisterDomain?.(domainSuggestion);
  };

  const renderRightBlock = () => {
    if (whoisUrl) {
      return (
        <Button
          variant="outlined"
          href={whoisUrl}
          target="_blank"
          startIcon={<DomainsIcon style={{fill: 'currentcolor'}} />}
          size="large"
        >
          {t('domains_search_suggestion_whois_button')}
        </Button>
      );
    }

    return (
      <>
        <Box className="domainPrice">
          {hasOldRegistrationPrice ? (
            <Box sx={(theme) => ({display: 'flex', alignItems: 'end', gap: theme.spacing(1)})}>
              <Typography variant="subtitle1">{domainSuggestion.registrationPriceFormatter.formatted}</Typography>
              <Typography variant="subtitle3" sx={{textDecoration: 'line-through'}}>
                {domainSuggestion.oldRegistrationPriceFormatter.formatted}
              </Typography>
            </Box>
          ) : (
            <Typography variant="subtitle1">{domainSuggestion.registrationPriceFormatter.formatted}</Typography>
          )}

          {domainSuggestion.renewalPriceFormatter.points ? (
            <Typography variant="body3" color="body.primary">
              {t('domains_search_suggestion_renew_price', {price: domainSuggestion.renewalPriceFormatter.formatted})}
            </Typography>
          ) : null}
        </Box>
        <Button variant={existWithSearchText ? 'contained' : 'outlined'} size="large" onClick={handleClickOrderButton}>
          {t('domains_search_suggestion_order_button')}
        </Button>
      </>
    );
  };

  const handleClickItem = () => {
    isAvailable && handleClickOrderButton();
  };

  return (
    <Container
      className={cn({isAvailable, isReady, existWithSearchText})}
      onMouseEnter={() => premiumDomainLabelRef.current?.showLabel?.()}
      onMouseLeave={() => premiumDomainLabelRef.current?.hideLabel?.()}
      onClick={handleClickItem}
    >
      <Box className="domainName">
        {isReady && existWithSearchText ? isAvailable ? <SuccessIcon /> : <ErrorIcon /> : null}

        <Typography
          variant={existWithSearchText ? 'h3' : 'body1'}
          color={isAvailable ? 'body.primary' : 'body.placeholder'}
        >
          {domainSuggestion.name}
        </Typography>

        {isReady && !isAvailable ? (
          <Box component="span" className="label label--taken">
            {t('domains_search_suggestion_taken_label')}
          </Box>
        ) : null}

        {isPremium ? <PremiumDomainLabel ref={premiumDomainLabelRef} /> : null}

        {isReady && isAvailable && discountPercent ? (
          <Box component="span" className="label label--discount">
            {t('domains_search_suggestion_save_label', {discount: discountPercent})}
          </Box>
        ) : null}
      </Box>

      <Box className="rightBox">{renderRightBlock()}</Box>
    </Container>
  );
});

export default DomainSuggestionItem;
