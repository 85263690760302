import {LoadingButton} from '@mui/lab';
import {Box, Button, styled, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2domains, ca2types} from '../../../api/proto';
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus.svg';
import {useStore} from '../../../stores/AppStore';
import DomainContact from '../../../stores/Domain/DomainContact';
import DomainContactForm, {IDomainContactFormRef} from './DomainContactForm';
import DomainContactItem from './DomainContactItem';

export const ContactsGridBox = styled(Box)(({theme}) => ({
  display: 'grid',
  columnGap: theme.spacing(4),
  gridTemplateColumns: '1fr',
  marginBottom: theme.spacing(2),
}));

export interface IDomainContactsRef extends Omit<IDomainContactFormRef, 'submitForm'> {
  getSelectedContact(): DomainContact | null;
  reset(): void;
}

interface IProps {
  existingContact?: DomainContact | null;
  isEditableMode?: boolean;
  hideAddNewContactButton?: boolean;
  onToggleContactForm?(showNewContactForm: boolean): void;
  onSubmitNewContactForm?(contact: DomainContact): void;
}

export const DomainContacts = observer(
  React.forwardRef<IDomainContactsRef, IProps>((props, ref) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {
      domainsStore: {contacts},
    } = useStore();

    const defaultSelectedContact = props.existingContact || contacts.list[0] || null;

    const domainContactFormRef = React.useRef<IDomainContactFormRef>(null);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [showNewContactForm, setShowNewContactForm] = React.useState<boolean>(!contacts.list.length);
    const [selectedContact, setSelectedContact] = React.useState<DomainContact | null>(defaultSelectedContact);

    const sortedContacts = React.useMemo(() => {
      return contacts.list
        .slice()
        .sort((a, b) => (a.id === defaultSelectedContact.id ? -1 : b.id === defaultSelectedContact.id ? 1 : 0));
    }, [contacts.list, defaultSelectedContact]);

    React.useImperativeHandle(ref, () => ({
      getFormFields: async () => {
        const fields = await domainContactFormRef.current?.getFormFields();
        return fields || null;
      },
      setApiErrors: (errors?: ca2domains.ContactError[] | null) => {
        domainContactFormRef.current?.setApiErrors(errors);
      },
      setFormValues: (values: ca2types.IDomainContact) => {
        domainContactFormRef.current?.setFormValues(values);
      },
      getSelectedContact: () => {
        return selectedContact;
      },
      reset: () => {
        setSelectedContact(defaultSelectedContact);
        setShowNewContactForm(!contacts.list.length);
        setLoading(false);
      },
    }));

    const handleOpenForm = () => {
      setShowNewContactForm(true);
      props.onToggleContactForm?.(true);
    };

    const handleCloseForm = () => {
      setShowNewContactForm(false);
      props.onToggleContactForm?.(false);
    };

    const handleSelectContact = (contact: DomainContact) => {
      setSelectedContact(contact);
    };

    const handleSaveAndUseNewAddress = async () => {
      setLoading(true);
      const newContact = await domainContactFormRef.current?.submitForm();

      if (newContact) {
        setSelectedContact(newContact);
        handleCloseForm();

        props.onSubmitNewContactForm?.(newContact);
      }

      setLoading(false);
    };

    return (
      <>
        <ContactsGridBox>
          {props.isEditableMode ? (
            sortedContacts.map((contact) => (
              <DomainContactItem
                key={contact.id}
                contact={contact}
                checked={selectedContact?.id === contact.id}
                editable
                selectable={contacts.list.length > 1}
                onSelect={handleSelectContact}
              />
            ))
          ) : selectedContact ? (
            <DomainContactItem key={selectedContact.id} contact={selectedContact} />
          ) : null}
        </ContactsGridBox>

        {showNewContactForm ? <DomainContactForm ref={domainContactFormRef} /> : null}

        {!props.hideAddNewContactButton ? (
          <>
            {!showNewContactForm && props.isEditableMode ? (
              <Button
                variant="outlined"
                size="large"
                onClick={handleOpenForm}
                startIcon={<PlusIcon style={{fill: theme.palette.body.primary}} />}
              >
                {t('domain_contacts_add_new_contact_button')}
              </Button>
            ) : props.isEditableMode && contacts.list.length ? (
              <Box>
                <LoadingButton
                  variant="contained"
                  size="large"
                  loading={loading}
                  sx={{marginRight: theme.spacing(2)}}
                  onClick={handleSaveAndUseNewAddress}
                >
                  {t('domain_contacts_save_and_use_new_address_button')}
                </LoadingButton>
                <Button variant="outlined" size="large" onClick={handleCloseForm}>
                  {t('action_button_cancel')}
                </Button>
              </Box>
            ) : null}
          </>
        ) : null}
      </>
    );
  }),
);

export default DomainContacts;
