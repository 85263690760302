import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as WarningIcon} from '../../../assets/icons/warning-orange.svg';
import DomainSuggestion from '../../../stores/Domain/DomainSuggestion';
import convertHoursToDaysAndHours from '../../../utils/convert/convertHoursToDaysAndHours';
import Typography from '../../UI/Typography';
import {OrderInstance} from '../PaymentViewer';

interface IProps {
  orderInstance: OrderInstance;
  hasBalance: boolean;
  renewDomain?: boolean;
}

const TopUpBalanceWarning: React.FC<IProps> = (props) => {
  const {t} = useTranslation();

  if (props.orderInstance instanceof DomainSuggestion) {
    return (
      <div className="top-up-balance-warning">
        <div className="top-up-balance-warning__body">
          <WarningIcon className="top-up-balance-warning__icon" />
          <div>
            <Typography variant="subtitle2">
              {props.hasBalance
                ? t('component_payment_viewer_founds_running_low')
                : t('component_payment_viewer_insufficient_balance_to_pay_domain')}
            </Typography>
            <Typography variant="body3" sx={({palette}) => ({color: palette.body.placeholder})}>
              {props.renewDomain && !props.orderInstance.hasBalanceToRenewDomain
                ? t('component_payment_viewer_top_up_balance_renew_domain_message', {
                    amount: props.orderInstance.renewalPriceFormatter.formatted,
                  })
                : t('component_payment_viewer_top_up_balance_message')}
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  const {days, hours} = convertHoursToDaysAndHours(props.orderInstance.hoursLeftWithBalance);

  return (
    <div className="top-up-balance-warning">
      {props.hasBalance && (
        <div className="top-up-balance-warning__top">
          <Typography variant="body3">{t('component_payment_viewer_balance_covers_in_hours')}</Typography>
          <Typography variant="subtitle2">
            {t('component_payment_viewer_balance_covers_in_hours_amount', {days, hours})}
          </Typography>
        </div>
      )}
      <div className="top-up-balance-warning__body">
        <WarningIcon className="top-up-balance-warning__icon" />
        <div>
          <Typography variant="subtitle2">
            {props.hasBalance
              ? t('component_payment_viewer_founds_running_low')
              : t('component_payment_viewer_not_enough_balance_message')}
          </Typography>
          <Typography variant="body3" sx={({palette}) => ({color: palette.body.placeholder})}>
            {t('component_payment_viewer_top_up_balance_message')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TopUpBalanceWarning;
