import {Box, Button, styled, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowLeftIcon} from '../../assets/icons/arrow-left.svg';
import {navigateToDomainInfo} from '../../routes/navigateTo';
import {Domain} from '../../stores/Domain';
import {DomainInfoTabs} from './DomainInfoViewer';

const Delimiter = styled(Box)(({theme}) => ({
  color: theme.palette.body.placeholder,
  margin: `0 ${theme.spacing(3)}`,
}));

interface IProps {
  domain: Domain;
  currentTab: DomainInfoTabs;
}

export const DomainRenewHead: React.FC<IProps> = ({currentTab, domain}) => {
  const {t} = useTranslation();

  const titlesData = Object.entries({
    [DomainInfoTabs.RenewPayment]: t('domain_info_renew_payment_tab'),
    [DomainInfoTabs.RenewConfirmation]: t('domain_info_renew_confirmation_tab'),
  });

  const handleClickBackButton = () => {
    navigateToDomainInfo(domain, DomainInfoTabs.Billing);
  };

  return (
    <div className="domain-info-head">
      <div className="domain-info-head__body">
        <div className="domain-info-head__back">
          <Button
            className="domain-info-head__back-btn"
            variant="outlined"
            onClick={handleClickBackButton}
            size="small"
            sx={(theme) => ({padding: theme.spacing(1), minWidth: 0})}
          >
            <ArrowLeftIcon width={24} height={24} />
          </Button>
        </div>
        <div className="domain-info-head__head">
          <Typography variant="body2" color="body.tertiary">
            {t('domain_info_renew_domain_label')}
          </Typography>
          <div>
            {titlesData.map(([tab, title], idx) => (
              <React.Fragment key={tab}>
                <Typography
                  variant="h4"
                  component="span"
                  sx={(theme) => ({
                    color: currentTab === tab ? theme.palette.body.primary : theme.palette.body.tertiary,
                  })}
                  color={currentTab === tab ? 'body.primary' : 'body.tertiary'}
                >
                  {title}
                </Typography>
                {idx !== titlesData.length - 1 ? <Delimiter component="span">/</Delimiter> : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainRenewHead;
