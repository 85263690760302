import {observer} from 'mobx-react';
import React from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';
import {animated} from 'react-spring';
import Div100vh from '../../components/Div100vh';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import {navigateToDomains} from '../../routes/navigateTo';
import Paths, {DomainsRouteParams} from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import DomainSuggestion from '../../stores/Domain/DomainSuggestion';
import ContactsInfo from './ContactsInfo';
import DomainsPageTabs from './DomainsPageTabs';
import DomainsSearch from './DomainsSearch';
import DomainsTable from './DomainsTable';
import RegisterDomain from './RegisterDomain';

export enum DomainsPageTab {
  Search = 'search',
  MyDomains = 'myDomains',
  Register = 'register',
  ContactsInfo = 'contactsInfo',
}

const tabsOrder = {
  [DomainsPageTab.Search]: 1,
  [DomainsPageTab.MyDomains]: 2,
  [DomainsPageTab.ContactsInfo]: 3,
  [DomainsPageTab.Register]: 4,
};

function isTabValid(value: string): value is DomainsPageTab {
  return Object.values(DomainsPageTab).includes(value as DomainsPageTab);
}

export const DomainsPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const params = useParams<DomainsRouteParams>();

  const {domainsView, domainsStore} = useStore();

  const [currentTab, setCurrentTab] = React.useState(DomainsPageTab.Search);
  const [prevTab, setPrevTab] = React.useState(DomainsPageTab.Register);
  const [selectedDomainSuggestion, setSelectedDomainSuggestion] = React.useState<DomainSuggestion | null>(null);

  React.useEffect(() => {
    if (
      !params.tabType ||
      !isTabValid(params.tabType) ||
      (params.tabType === DomainsPageTab.Register && !selectedDomainSuggestion)
    ) {
      navigate(
        generatePath(Paths.Domains, {
          tabType: DomainsPageTab.Search,
        }),
        {replace: true},
      );
    } else {
      setPrevTab(currentTab);
      setCurrentTab(params.tabType);
    }

    return () => {
      domainsView.reset();
    };
  }, [domainsView, selectedDomainSuggestion, params, currentTab, navigate]);

  const handleChangeTab = (tab: DomainsPageTab) => {
    setPrevTab(currentTab);
    setCurrentTab(tab);

    navigate(
      generatePath(Paths.Domains, {
        tabType: tab,
      }),
    );
  };

  const handleRegisterDomain = (suggestion: DomainSuggestion) => {
    setSelectedDomainSuggestion(suggestion);
    handleChangeTab(DomainsPageTab.Register);
  };

  const handleClickRegisterDomainBackButton = () => {
    handleChangeTab(DomainsPageTab.Search);
    navigateToDomains(DomainsPageTab.Search);
  };

  const handleCancelRegistration = () => {
    handleChangeTab(DomainsPageTab.Search);
    setSelectedDomainSuggestion(null);
  };

  const switchToSearchDomains = () => {
    handleChangeTab(DomainsPageTab.Search);
  };

  const transitions = useOrderTransition({
    currentStep: tabsOrder[currentTab],
    prevStep: tabsOrder[prevTab],
  });

  if (selectedDomainSuggestion && currentTab === DomainsPageTab.Register) {
    return (
      <RegisterDomain
        domainSuggestion={selectedDomainSuggestion}
        onClickBackButton={handleClickRegisterDomainBackButton}
        onCancelRegistration={handleCancelRegistration}
      />
    );
  }

  return (
    <Div100vh className="page page--domains">
      <DomainsPageTabs currentTab={currentTab} onChange={handleChangeTab} />

      <div className="page__inner">
        {transitions((styles, step) =>
          step === tabsOrder[DomainsPageTab.Search] ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <DomainsSearch onRegisterDomain={handleRegisterDomain} />
            </animated.div>
          ) : step === tabsOrder[DomainsPageTab.MyDomains] ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <DomainsTable domains={domainsStore.domains} onSwitchToSearchDomains={switchToSearchDomains} />
            </animated.div>
          ) : step === tabsOrder[DomainsPageTab.ContactsInfo] ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <ContactsInfo />
            </animated.div>
          ) : null,
        )}
      </div>
    </Div100vh>
  );
});

export default DomainsPage;
