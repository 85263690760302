import Long from 'long';
import {computed, configure, makeAutoObservable, observable} from 'mobx';
import {MobXProviderContext} from 'mobx-react';
import React from 'react';
import Network, {NetworkEvent} from '../api/network';
import WsNetwork, {WsNetworkEvent} from '../api/wsNetwork';
import {authPageOpen} from '../routes/routes';
import AuthStore from './AuthStore';
import BillingStore from './BillingStore';
import DomainsStore from './Domain';
import DomainsView from './Domain/DomainsView';
import InstancesStore from './Instance';
import {InstanceView} from './Instance/InstanceView';
import LayoutStore from './LayoutStore';
import ModalsStore from './ModalsStore';
import NotificationsStore from './NotificationsStore';
import PlansStore from './Plan';
import ServersStore from './Server';
import {SshKeysStore} from './SshKeysStore';
import UserStore from './UserStore';
import UtilsStore from './UtilsStore';

configure({
  enforceActions: 'observed',
  disableErrorBoundaries: true,
});

export class AppStore {
  api: Network = new Network(this);
  authStore: AuthStore = new AuthStore(this);
  wsApi: WsNetwork = new WsNetwork(this);
  utils: UtilsStore = new UtilsStore(this);

  userStore: UserStore = new UserStore(this);

  layoutStore: LayoutStore = new LayoutStore();
  notification: NotificationsStore = new NotificationsStore();
  modals: ModalsStore = new ModalsStore();

  serversStore: ServersStore = new ServersStore(this);

  plansStore: PlansStore = new PlansStore(this);
  instancesStore: InstancesStore = new InstancesStore(this);
  instanceView: InstanceView = new InstanceView(this);
  sshKeysStore: SshKeysStore = new SshKeysStore(this);

  billingStore: BillingStore = new BillingStore(this);

  domainsStore: DomainsStore = new DomainsStore(this);
  domainsView: DomainsView = new DomainsView(this);

  @observable private isInit_: boolean = false;
  @observable private isAuthenticatedInit_: boolean = false;

  constructor() {
    makeAutoObservable(this);

    this.init();

    this.api.on(NetworkEvent.INVALID_TOKEN, () => {
      this.authStore.logout();
    });

    this.wsApi.on(WsNetworkEvent.INVALID_TOKEN, () => {
      this.authStore.logout();
    });
  }

  @computed get isAppInit() {
    if (this.authStore.isLoggedIn && !authPageOpen()) {
      return this.isInit_ && this.isAuthenticatedInit_;
    }

    return this.isInit_;
  }

  get lightThemeChosen(): boolean {
    return this.layoutStore.uiTheme.lightThemeChosen;
  }

  reset = () => {
    this.authStore.reset();
    this.userStore.reset();

    this.serversStore.reset();

    this.plansStore.reset();
    this.instancesStore.reset();
    this.instanceView.reset();
    this.sshKeysStore.reset();

    this.domainsStore.reset();
    this.domainsView.reset();
    this.billingStore.reset();

    this.isAuthenticatedInit_ = false;
  };

  init = async () => {
    if (this.isInit_) {
      return;
    }

    this.authStore.init();
    await this.utils.init();
    await this.userStore.init();

    if (this.authStore.isLoggedIn) {
      this.authenticatedInit();
    }

    this.isInit_ = true;
  };

  authenticatedInit = async () => {
    await this.plansStore.filter.loadOptions();
    await this.serversStore.init();

    await this.domainsStore.init();
    await this.sshKeysStore.init();
    await this.billingStore.init();

    await this.instancesStore.init();

    this.isAuthenticatedInit_ = true;
  };
}

const store = new AppStore();

window.appStore = store;
window.Long = Long;

export default store;

export function useStore(): AppStore {
  return React.useContext(MobXProviderContext).store;
}
