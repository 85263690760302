import {Box, styled} from '@mui/material';
import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2types} from '../../../../api/proto';
import Typography from '../../../../components/UI/Typography';
import {useStore} from '../../../../stores/AppStore';

const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  gap: theme.spacing(2),
  justifyContent: 'space-between',
}));

const TopTldTypography = styled(Typography)(({theme}) => ({
  flex: '1 1 auto',
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '12px',
  cursor: 'pointer',

  '& .priceLabel': {
    marginLeft: theme.spacing(1),
  },

  '&:hover': {
    border: `1px solid ${theme.palette.border.secondary}`,
    backgroundColor: theme.palette.backgroundSecondary,
  },

  '&.selected': {
    borderColor: 'transparent',
    backgroundColor: theme.palette.backgroundTertiary,
    cursor: 'default',
  },
}));

interface IProps {
  topTlds: ca2types.ITLD[];
}

export const TopTldsBar: React.FC<IProps> = observer(({topTlds}) => {
  const {domainsStore} = useStore();
  const {t} = useTranslation();

  const handleClickTldItem = (name?: string | null) => {
    name && domainsStore.search.addTldName(name);
  };

  if (!topTlds.length) {
    return null;
  }

  return (
    <Container>
      {topTlds.map(({name, minRegistrationPrice}) => (
        <TopTldTypography
          className={cn({selected: name && domainsStore.search.selectedTldNames.includes(name)})}
          key={name}
          onClick={() => handleClickTldItem(name)}
        >
          {`.${name}`}
          <span className="priceLabel">
            {t('domains_search_filter_top_tld_label', {price: minRegistrationPrice?.formatted})}
          </span>
        </TopTldTypography>
      ))}
    </Container>
  );
});

export default TopTldsBar;
