import {Box, Button, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getApiDomainContactDeleteError} from '../../../api/getApiError';
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus.svg';
import SearchInput from '../../../components/UI/SearchInput';
import Typography from '../../../components/UI/Typography';
import PageContentLayout from '../../../layouts/PageContentLayout';
import {useStore} from '../../../stores/AppStore';
import DomainContact from '../../../stores/Domain/DomainContact';
import {ModalType} from '../../../stores/ModalsStore';
import DomainContactItem from '../DomainContacts/DomainContactItem';

export const ContactInfoViewer: React.FC = observer(() => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {
    modals,
    notification,
    domainsStore: {contacts},
  } = useStore();

  const contactsList = contacts.list;

  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [filteredContacts, setFilteredContacts] = React.useState<DomainContact[]>(contactsList);

  React.useEffect(() => {
    setFilteredContacts(contactsList);
  }, [contactsList]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value === '') {
      setFilteredContacts(contactsList);
    } else {
      const filtered = contactsList.filter((contact) => {
        return (
          (contact.firstName && contact.firstName.toLowerCase().includes(value.toLowerCase())) ||
          (contact.lastName && contact.lastName.toLowerCase().includes(value.toLowerCase())) ||
          (contact.mailingAddress && contact.mailingAddress.toLowerCase().includes(value.toLowerCase())) ||
          (contact.mailingCity && contact.mailingCity.toLowerCase().includes(value.toLowerCase())) ||
          (contact.mailingState && contact.mailingState.toLowerCase().includes(value.toLowerCase())) ||
          (contact.mailingZip && contact.mailingZip.toLowerCase().includes(value.toLowerCase())) ||
          (contact.mailingCountryCode && contact.mailingCountryCode.toLowerCase().includes(value.toLowerCase())) ||
          (contact.emailAddress && contact.emailAddress.toLowerCase().includes(value.toLowerCase())) ||
          (contact.phoneNumber && contact.phoneNumber.toLowerCase().includes(value.toLowerCase())) ||
          (contact.domainIds && contact.domainIds.some((id) => id.toString().includes(value)))
        );
      });
      setFilteredContacts(filtered);
    }
  };

  const handleClear = () => {
    setSearchTerm('');
    setFilteredContacts(contactsList);
  };

  const deleteContact = async (contactId?: number | null) => {
    if (!contactId) {
      return;
    }

    const {res, error} = await contacts.deleteContact(contactId);

    if (error) {
      notification.error(error.message);
      return;
    }

    if (res?.errors?.length) {
      for (const error of res.errors) {
        const errorMessage = getApiDomainContactDeleteError(error);
        notification.error(errorMessage);
      }

      return;
    }

    notification.success(t('domains_contacts_info_delete_successfully_notification'));

    setFilteredContacts(contactsList.filter((contact) => contact.id !== contactId));
  };

  const handleDeleteContact = (contact: DomainContact) => {
    if (contact.associatedDomains.length) {
      modals.open(ModalType.DELETE_DOMAIN_CONTACT, {
        domainContact: contact,
        callbacks: {
          submit: () => {
            setFilteredContacts(contactsList.filter(({id}) => id !== contact.id));
          },
        },
      });

      return;
    }

    modals.open(ModalType.CONFIRM, {
      maxWidth: 'sm',
      headerText: t('modal_confirm_delete_domain_contact_header_text'),
      submitButtonText: t('action_button_delete'),
      submitButtonColor: 'error',
      content: <Typography variant="body1">{t('modal_confirm_delete_domain_contact_text')}</Typography>,
      callbacks: {
        submit: async () => {
          await deleteContact(contact.id);
        },
      },
    });
  };

  const handleAddNewContact = () => {
    modals.open(ModalType.EDIT_DOMAIN_CONTACT);
  };

  const handleEditContact = (contact: DomainContact) => {
    modals.open(ModalType.EDIT_DOMAIN_CONTACT, {
      domainContact: contact,
    });
  };

  return (
    <Box sx={{maxWidth: 815, height: '100%'}}>
      <PageContentLayout sx={{padding: theme.spacing(4), paddingTop: '0px'}}>
        <Box
          sx={{
            position: 'sticky',
            paddingTop: theme.spacing(4),
            top: 0,
            backgroundColor: theme.palette.backgroundPrimary,
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: theme.spacing(4),
            }}
          >
            <Typography variant="label1" gutterBottom>
              {t('domains_contacts_info_title')}
            </Typography>
            <Button
              size="large"
              endIcon={<PlusIcon style={{fill: theme.palette.body.tint}} />}
              onClick={handleAddNewContact}
            >
              {t('domains_contacts_info_add_contact_button')}
            </Button>
          </Box>

          {contactsList.length > 1 ? (
            <SearchInput
              value={searchTerm}
              placeholder={t('domains_contacts_search_placeholder')}
              onChange={handleChange}
              onClear={handleClear}
              sx={{marginBottom: theme.spacing(2), backgroundColor: theme.palette.backgroundPrimary}}
            />
          ) : null}
        </Box>

        {filteredContacts.map((contact) => (
          <DomainContactItem
            key={contact.id}
            contact={contact}
            onDelete={handleDeleteContact}
            onEdit={handleEditContact}
            showConnectedDomains
          />
        ))}
      </PageContentLayout>
    </Box>
  );
});

export default ContactInfoViewer;
