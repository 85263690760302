import {LoadingButton} from '@mui/lab';
import {Box, Button, DialogActions, DialogContent, Typography, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getApiDomainContactError} from '../api/getApiError';
import DomainContactForm, {IDomainContactFormRef} from '../pages/DomainsPage/DomainContacts/DomainContactForm';
import {useStore} from '../stores/AppStore';
import {ModalItem} from '../stores/ModalsStore/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

const ModalEditDomainContact: React.FC<IProps> = observer((props) => {
  const {
    domainsStore: {contacts},
    notification,
  } = useStore();
  const {t} = useTranslation();
  const theme = useTheme();

  const modalRef = React.useRef<ModalDialogRef>(null);

  const [loading, setLoading] = React.useState(false);

  const domainContactFormRef = React.useRef<IDomainContactFormRef>(null);

  const handleClose = () => {
    modalRef.current?.close();
  };

  const handleSubmit = async () => {
    const updateContactFields = await domainContactFormRef.current?.getFormFields();

    if (!updateContactFields) {
      return;
    }

    setLoading(true);

    const {res, error} = await contacts.createOrUpdateContact({
      ...updateContactFields,
      id: props.modal.data.domainContact?.id,
    });

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors} = res;

      if (errors?.length) {
        errors.forEach((error) => {
          const errorMessage = getApiDomainContactError(error);
          notification.error(errorMessage);
        });

        setLoading(false);
        return;
      }

      if (props.modal.data.domainContact?.id) {
        notification.success(t('modal_edit_domain_contact_success_notification'));
      } else {
        notification.success(t('modal_edit_domain_contact_create_success_notification'));
      }

      handleClose();
    }

    setLoading(false);
  };

  return (
    <ModalDialog
      modal={props.modal}
      ref={modalRef}
      maxWidth="sm"
      fullWidth
      headerText={t('modal_delete_domain_contact_header_text')}
    >
      {props.modal.data.domainContact?.associatedDomains.length ? (
        <DialogContent
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: theme.palette.backgroundPrimary,
            borderBottom: `1px solid ${theme.palette.border.primary}`,
          }}
        >
          <Typography variant="body1" gutterBottom>
            {t('modal_edit_domain_contact_applied_domains_title')}
          </Typography>
          <Box sx={{display: 'flex', gap: theme.spacing(2), flexWrap: 'wrap'}}>
            {props.modal.data.domainContact?.associatedDomains.map((domain) => (
              <Box
                key={domain.domainId}
                component="span"
                sx={{
                  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                  backgroundColor: theme.palette.backgroundSecondary,
                  borderRadius: '8px',
                }}
              >
                {domain.name}
              </Box>
            ))}
          </Box>
        </DialogContent>
      ) : null}

      <DialogContent className="custom-scroll">
        <DomainContactForm ref={domainContactFormRef} initialContact={props.modal.data.domainContact} />
      </DialogContent>

      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          {t('action_button_cancel')}
        </Button>
        <LoadingButton size="large" variant="contained" color="primary" onClick={handleSubmit} loading={loading}>
          {t('action_button_save')}
        </LoadingButton>
      </DialogActions>
    </ModalDialog>
  );
});

export default ModalEditDomainContact;
