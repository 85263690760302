import {Button} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router';
import {animated} from 'react-spring';
import {ReactComponent as ArrowLeftIcon} from '../../assets/icons/arrow-left.svg';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import Paths from '../../routes/Paths';
import getQueryStringParam from '../../utils/getQueryStringParam';
import CreatePasswordForm from './CreatePasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import VerificationCodeForm from './VerificationCodeForm';

export enum ForgotPasswordPageStep {
  RESET_PASSWORD,
  VERIFICATION_CODE,
  CREATE_PASSWORD,
}

type LocationState = {
  step?: ForgotPasswordPageStep;
};

const debug: string = getQueryStringParam('debug');
const qsStep: string = getQueryStringParam('step');

const INITIAL_STEP = debug && qsStep ? parseFloat(qsStep) : ForgotPasswordPageStep.RESET_PASSWORD;

const ForgotPasswordPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;

  const {t} = useTranslation();

  const [prevStep, setPrevStep] = React.useState<number>(ForgotPasswordPageStep.CREATE_PASSWORD);
  const [currentStep, setCurrentStep] = React.useState<number>(INITIAL_STEP);

  const switchToStep = React.useCallback(
    (step: ForgotPasswordPageStep) => {
      setPrevStep(currentStep);
      setCurrentStep(step);
    },
    [currentStep],
  );

  const handleClickBackButton = () => {
    navigate(Paths.Authorization);
  };

  const handleSubmitResetPassword = () => {
    switchToStep(ForgotPasswordPageStep.VERIFICATION_CODE);
  };

  React.useEffect(() => {
    if (typeof state.step === 'number') {
      switchToStep(state.step);
    }
  }, [state, switchToStep]);

  const handleSubmitCreatePassword = () => {
    navigate(Paths.Root);
  };

  const handleEditEmail = () => {
    switchToStep(ForgotPasswordPageStep.RESET_PASSWORD);
  };

  const transitions = useOrderTransition({
    prevStep,
    currentStep,
  });

  const bodyHeight: Record<ForgotPasswordPageStep, number> = {
    [ForgotPasswordPageStep.RESET_PASSWORD]: 266,
    [ForgotPasswordPageStep.VERIFICATION_CODE]: 382,
    [ForgotPasswordPageStep.CREATE_PASSWORD]: 452,
  };

  return (
    <div className="auth-page">
      {currentStep === ForgotPasswordPageStep.RESET_PASSWORD && (
        <div className="auth-page__head">
          <Button
            className="auth-page__back-button"
            variant="outlined"
            onClick={handleClickBackButton}
            startIcon={<ArrowLeftIcon />}
          >
            {t('auth_go_prev_step_button')}
          </Button>
        </div>
      )}

      <div className="auth-page__body" style={{minHeight: bodyHeight[currentStep] || '100%'}}>
        {transitions((styles, step) =>
          step === ForgotPasswordPageStep.RESET_PASSWORD ? (
            <animated.div style={styles} className="animated-tab">
              <ResetPasswordForm onSubmit={handleSubmitResetPassword} />
            </animated.div>
          ) : step === ForgotPasswordPageStep.VERIFICATION_CODE ? (
            <animated.div style={styles} className="animated-tab">
              <VerificationCodeForm onEditEmail={handleEditEmail} />
            </animated.div>
          ) : step === ForgotPasswordPageStep.CREATE_PASSWORD ? (
            <animated.div style={styles} className="animated-tab">
              <CreatePasswordForm onSubmit={handleSubmitCreatePassword} />
            </animated.div>
          ) : null,
        )}
      </div>
    </div>
  );
});

export default ForgotPasswordPage;
