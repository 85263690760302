import {observer} from 'mobx-react';
import React from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';
import {animated} from 'react-spring';
import Div100vh from '../../components/Div100vh';
import PaymentViewer from '../../components/PaymentViewer';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import {navigateToBilling} from '../../routes/navigateTo';
import Paths, {BillingRouteParams} from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import BillingDetailsViewer from './BillingDetailsViewer';
import BillingPageTopBar from './BillingPageTopBar';

export enum BillingPageTab {
  BILLING_DETAILS = 'details',
  TOP_UP_BALANCE = 'topUpBalance',
}

const tabsOrder = {
  [BillingPageTab.BILLING_DETAILS]: 1,
  [BillingPageTab.TOP_UP_BALANCE]: 2,
};

function isTabValid(value: string): value is BillingPageTab {
  return Object.values(BillingPageTab).includes(value as BillingPageTab);
}

export const BillingPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const params = useParams<BillingRouteParams>();

  const {billingStore} = useStore();

  const [currentTab, setCurrentTab] = React.useState<BillingPageTab>(BillingPageTab.BILLING_DETAILS);
  const prevTab =
    currentTab === BillingPageTab.BILLING_DETAILS ? BillingPageTab.TOP_UP_BALANCE : BillingPageTab.BILLING_DETAILS;

  React.useEffect(() => {
    if (!params.tabType || !isTabValid(params.tabType)) {
      navigate(
        generatePath(Paths.Billing, {
          tabType: BillingPageTab.BILLING_DETAILS,
        }),
        {replace: true},
      );
    }

    if (params.tabType && isTabValid(params.tabType)) {
      setCurrentTab(params.tabType);
    }
  }, [params, navigate]);

  React.useEffect(() => {
    billingStore.initBillingPageState();

    return () => {
      billingStore.resetBillingPageState();
    };
  }, [billingStore]);

  const switchToBillingDetails = () => {
    navigateToBilling(BillingPageTab.BILLING_DETAILS);
  };

  const switchToTopUp = () => {
    navigateToBilling(BillingPageTab.TOP_UP_BALANCE);
  };

  const transitions = useOrderTransition({
    currentStep: tabsOrder[currentTab],
    prevStep: tabsOrder[prevTab],
  });

  return (
    <Div100vh className="page page--billing">
      <BillingPageTopBar currentTab={currentTab} onBack={switchToBillingDetails} />

      <div className="page__inner">
        {transitions((styles, step) =>
          step === tabsOrder[BillingPageTab.TOP_UP_BALANCE] ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <PaymentViewer onCancelPaymentOrder={switchToBillingDetails} />
            </animated.div>
          ) : step === tabsOrder[BillingPageTab.BILLING_DETAILS] ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <BillingDetailsViewer onTopUpBalance={switchToTopUp} />
            </animated.div>
          ) : null,
        )}
      </div>
    </Div100vh>
  );
});

export default BillingPage;
