export enum ModalType {
  CONFIRM = 'CONFIRM',
  SELECT_SSH_KEY = 'SELECT_SSH_KEY',
  TLD_FILTER = 'TLD_FILTER',
  ADD_SSH_KEY = 'ADD_SSH_KEY',
  EDIT_SSH_KEY = 'EDIT_SSH_KEY',
  DELETE_SSH_KEY = 'DELETE_SSH_KEY',
  DELETE_DOMAIN_CONTACT = 'DELETE_DOMAIN_CONTACT',
  EDIT_DOMAIN_CONTACT = 'EDIT_DOMAIN_CONTACT',
}
