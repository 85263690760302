import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  useTheme,
} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {TableVirtuoso} from 'react-virtuoso';
import {ReactComponent as SearchIcon} from '../../../assets/icons/search.svg';
import {ReactComponent as TableSortDefaultIcon} from '../../../assets/icons/table-sort-default.svg';
import {ReactComponent as TableSortIcon} from '../../../assets/icons/table-sort.svg';
import SearchInput from '../../../components/UI/SearchInput';
import Typography from '../../../components/UI/Typography';
import {t} from '../../../i18n';
import {navigateToDomainInfo} from '../../../routes/navigateTo';
import {Domain} from '../../../stores/Domain';
import {getComparator, SortOrder, tableSort} from '../../../utils/tableSort';
import DomainTableRow from './DomainTableRow';

export enum DomainsColumnType {
  DomainName = 'domain_name',
  RegistrationDate = 'registration_date',
  ExpirationDate = 'expiration_date',
  AutoRenewal = 'auto_renewal',
}

type Column = {
  id: DomainsColumnType;
  label: string;
  width?: number;
};

const COLUMN_MIN_WIDTH = 150; //px;

export const DOMAINS_TABLE_COLUMNS: Column[] = [
  {
    id: DomainsColumnType.DomainName,
    label: t('domains_table_domain_name_column'),
    width: COLUMN_MIN_WIDTH,
  },
  {
    id: DomainsColumnType.RegistrationDate,
    label: t('domains_table_registration_date_column'),
    width: COLUMN_MIN_WIDTH,
  },
  {
    id: DomainsColumnType.ExpirationDate,
    label: t('domains_table_expiration_date_column'),
    width: COLUMN_MIN_WIDTH,
  },
  {
    id: DomainsColumnType.AutoRenewal,
    label: t('domains_table_auto_renewal_column'),
    width: COLUMN_MIN_WIDTH,
  },
];

const columnPropsDictionary: Record<Exclude<DomainsColumnType, DomainsColumnType.AutoRenewal>, keyof Domain> = {
  [DomainsColumnType.DomainName]: 'name',
  [DomainsColumnType.ExpirationDate]: 'expiresAtTimestamp',
  [DomainsColumnType.RegistrationDate]: 'createdAtTimestamp',
};

const VirtuosoTableComponents = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => <TableContainer {...props} ref={ref} />),
  Table: (props) => <Table {...props} sx={{borderCollapse: 'separate'}} />,
  TableRow: (props) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
};

interface IProps {
  domains: Domain[];
  onSwitchToSearchDomains?(): void;
}

export const DomainsTable: React.FC<IProps> = observer((props) => {
  const theme = useTheme();
  const [searchText, setSearchText] = React.useState<string>('');

  const [order, setOrder] = React.useState<SortOrder>(SortOrder.asc);
  const [orderBy, setOrderBy] = React.useState<DomainsColumnType>(DomainsColumnType.ExpirationDate);

  const handleRequestSort = (_, property: DomainsColumnType) => {
    const isAsc = orderBy === property && order === SortOrder.asc;
    setOrder(isAsc ? SortOrder.desc : SortOrder.asc);
    setOrderBy(property);
  };

  const sortedDomains = React.useMemo(() => {
    const sorted = tableSort(props.domains, getComparator<Domain>(order, columnPropsDictionary[orderBy]));
    if (!searchText) {
      return sorted;
    }

    return sorted.filter(({name}) => name?.includes(searchText));
  }, [props.domains, searchText, order, orderBy]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleClear = () => {
    setSearchText('');
  };

  const handleRowClick = (domain: Domain) => {
    navigateToDomainInfo(domain);
  };

  function fixedHeaderContent() {
    return (
      <TableRow>
        {DOMAINS_TABLE_COLUMNS.map((column) => {
          const active = orderBy === column.id;
          const direction = orderBy === column.id ? order : SortOrder.asc;

          if (column.id === DomainsColumnType.AutoRenewal) {
            return (
              <TableCell key={column.id} align="left" style={{width: column.width}}>
                <Typography variant="body2" color="body.primary">
                  {column.label}
                </Typography>
              </TableCell>
            );
          }

          return (
            <TableCell key={column.id} style={{width: column.width}}>
              <TableSortLabel
                active={active}
                direction={direction}
                onClick={(event) => handleRequestSort(event, column.id)}
                IconComponent={active ? TableSortIcon : TableSortDefaultIcon}
                sx={{justifyContent: 'space-between'}}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  if (!props.domains.length) {
    return (
      <Box
        sx={{
          padding: theme.spacing(4),
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            {t('domains_table_no_domains_title')}
          </Typography>
          <Typography variant="body1" color="body.tertiary" sx={{marginBottom: theme.spacing(5)}}>
            {t('domains_table_no_domains_description')}
          </Typography>

          <Button size="large" onClick={props.onSwitchToSearchDomains}>
            <SearchIcon style={{fill: 'currentcolor', marginRight: theme.spacing(2)}} />
            {t('domains_table_no_domains_search_button')}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{padding: theme.spacing(4), height: '100%', display: 'flex', flexDirection: 'column'}}>
      <SearchInput
        placeholder={t('domains_table_search_placeholder')}
        onChange={handleChange}
        onClear={handleClear}
        sx={{marginBottom: theme.spacing(4), backgroundColor: theme.palette.backgroundPrimary}}
      />
      <TableVirtuoso
        data={sortedDomains}
        components={{
          ...VirtuosoTableComponents,
          TableRow: (props) => (
            <TableRow onClick={() => handleRowClick(props.item)} {...props} hover sx={{cursor: 'pointer'}} />
          ),
        }}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={(index, domain) => <DomainTableRow key={index} domain={domain} />}
      />
    </Box>
  );
});

export default DomainsTable;
