import {Box, styled, useTheme} from '@mui/material';
import cn from 'classnames';
import {getName} from 'country-list';
import React from 'react';
import {useTranslation} from 'react-i18next';
import CheckboxField from '../../../components/UI/CheckboxField';
import DropdownMenu from '../../../components/UI/DropdownMenu';
import MenuItem from '../../../components/UI/MenuItem';
import RadioField from '../../../components/UI/RadioField';
import Typography from '../../../components/UI/Typography';
import DomainContact from '../../../stores/Domain/DomainContact';

const CustomButton = styled(Box)(({theme}) => ({
  position: 'relative',
  width: '32px',
  height: '32px',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color: theme.palette.body.placeholder,
  '&:hover, &.active': {
    color: theme.palette.body.primary,
  },
  '&:after': {
    position: 'absolute',
    content: '"..."',
    height: '10px',
    overflow: 'hidden',
    lineHeight: 0,
    fontSize: '24px',
  },
}));

const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  width: '100%',
  marginBottom: theme.spacing(2),
  overflow: 'hidden',

  '&.selectable:hover': {
    backgroundColor: theme.palette.backgroundSecondary,
    cursor: 'pointer',
  },
}));

const Inner = styled(Box)(({theme}) => ({
  display: 'flex',
  padding: theme.spacing(2),

  '&:last-child': {
    borderTop: `1px solid ${theme.palette.border.primary}`,
  },

  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

interface IProps {
  contact: DomainContact;
  editable?: boolean;
  selectable?: boolean;
  checked?: boolean;
  showConnectedDomains?: boolean;
  onSelect?(contact: DomainContact);
  onEdit?(contact: DomainContact);
  onDelete?(contact: DomainContact);
}

export const DomainContactItem: React.FC<IProps> = ({
  contact,
  selectable,
  editable,
  checked,
  showConnectedDomains,
  onSelect,
  onEdit,
  onDelete,
}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(menuEl);

  const handleClickMenuButton = (e: React.MouseEvent<HTMLDivElement>) => {
    setMenuEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuEl(null);
  };

  const handleSelect = () => {
    if (selectable && contact) {
      onSelect?.(contact);
    }
  };

  const handleEdit = () => {
    onEdit?.(contact);
    handleCloseMenu();
  };

  const handleDelete = () => {
    onDelete?.(contact);
    handleCloseMenu();
  };

  const addressString = [
    getName(contact.mailingCountryCode),
    contact.mailingCity,
    contact.mailingAddress,
    contact.mailingState,
    contact.mailingZip,
  ]
    .filter((item) => !!item)
    .join(', ');

  return (
    <Container className={cn({selectable})} onClick={handleSelect}>
      <Inner>
        <Box>
          {selectable && editable ? (
            <RadioField checked={checked} sx={{padding: 0}} disableRipple />
          ) : editable ? (
            <CheckboxField sx={{padding: 0}} checked disabled />
          ) : null}
        </Box>

        <Box
          sx={{
            flex: '1 1 auto',
            paddingLeft: selectable ? theme.spacing(2) : '0px',
            width: selectable || editable ? 'calc(100% - 30px)' : '100%',
          }}
        >
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant="body1" sx={{marginTop: theme.spacing(0), marginBottom: theme.spacing(1)}}>
              <Box
                sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
              >{`${contact.firstName} ${contact.lastName}`}</Box>
            </Typography>

            {onEdit || onDelete ? (
              <>
                <CustomButton onClick={handleClickMenuButton} />

                <DropdownMenu
                  MenuListProps={{sx: {padding: 0, minWidth: 85}}}
                  anchorEl={menuEl}
                  open={open}
                  onClose={handleCloseMenu}
                >
                  {onEdit ? <MenuItem onClick={handleEdit}>{t('action_button_edit')}</MenuItem> : null}

                  {onDelete ? (
                    <MenuItem onClick={handleDelete} sx={{color: theme.palette.error.primary}}>
                      {t('action_button_delete')}
                    </MenuItem>
                  ) : null}
                </DropdownMenu>
              </>
            ) : null}
          </Box>

          <Typography
            variant="body2"
            color="body.tertiary"
            sx={{
              marginBottom: theme.spacing(0),
            }}
          >
            <Box sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{addressString}</Box>
          </Typography>
          <Typography variant="body2" color="body.tertiary">
            <Box
              sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
            >{`${contact.emailAddress}, ${contact.phoneNumber}`}</Box>
          </Typography>
        </Box>
      </Inner>

      {showConnectedDomains && contact.associatedDomains.length ? (
        <Inner sx={{display: 'flex', flexDirection: 'column'}}>
          <Typography variant="body1" color="body.secondary" gutterBottom>
            {t('domain_contacts_connected_domains_title')}
          </Typography>
          <Box sx={{display: 'flex', gap: theme.spacing(2), flexWrap: 'wrap'}}>
            {contact.associatedDomains.map((domain) => (
              <Box
                key={domain.domainId}
                component="span"
                sx={{
                  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                  backgroundColor: theme.palette.backgroundSecondary,
                  borderRadius: '8px',
                }}
              >
                {domain.name}
              </Box>
            ))}
          </Box>
        </Inner>
      ) : null}
    </Container>
  );
};

export default DomainContactItem;
