import createTheme from '@mui/material/styles/createTheme';
import buildPalette from './palette';

export const getTheme = (isLightTheme) => {
  let theme = createTheme({
    palette: buildPalette(isLightTheme),
    spacing: [2, 4, 8, 12, 16, 24, 40, 48, 64, 80],
    size: {
      fontSize: {
        XL: '2rem', // 32px
        L: '1.75rem', // 28px
        M: '1.5rem', // 24px
        SM: '1.25rem', // 20px
        S: '1rem', // 16px
        XS: '0.875rem', // 14px
        XXS: '0.75rem', // 12px
      },
      fontWeight: {
        light: 300,
        regular: 400,
        bold: 550,
      },
      lineHeight: {
        XL: '2.5rem', // 40px
        L: '2.25rem', // 36px
        M: '2rem', // 32px
        SM: '1.75rem', // 28px
        S: '1.5rem', // 24px
        XS: '1.25rem', // 20px
        XXS: '1rem', // 16px
      },
      paragraphSpace: {
        L: '0.5rem', // 8px,
        S: '0.25rem', // 4px
      },
      letterSpacing: {
        XL: '-0.96px',
        L: '-0.56',
        M: '-0.48px',
        SM: '-0.4px',
        S: '-0.32px',
        XS: '-0.28px',
        XXS: '-0.12px',
      },
    },
  });

  theme = createTheme(theme, {
    typography: {
      fontFamily: '"Inter", sans-serif',
      button: {
        textTransform: 'none',
        fontWeight: theme.size.fontWeight.bold,
      },
      h1: {
        fontStyle: 'normal',
        fontWeight: theme.size.fontWeight.bold,
        fontSize: theme.size.fontSize.XL,
        lineHeight: theme.size.lineHeight.XL,
        letterSpacing: theme.size.letterSpacing.XL,
      },
      h2: {
        fontStyle: 'normal',
        fontWeight: theme.size.fontWeight.bold,
        fontSize: theme.size.fontSize.L,
        lineHeight: theme.size.lineHeight.L,
        letterSpacing: theme.size.letterSpacing.L,
      },
      h3: {
        fontStyle: 'normal',
        fontWeight: theme.size.fontWeight.bold,
        fontSize: theme.size.fontSize.M,
        lineHeight: theme.size.lineHeight.M,
        letterSpacing: theme.size.letterSpacing.M,
      },
      h4: {
        fontStyle: 'normal',
        fontWeight: theme.size.fontWeight.bold,
        fontSize: theme.size.fontSize.SM,
        lineHeight: theme.size.lineHeight.SM,
        letterSpacing: theme.size.letterSpacing.SM,
      },
      subtitle1: {
        fontStyle: 'normal',
        fontWeight: theme.size.fontWeight.bold,
        fontSize: theme.size.fontSize.S,
        lineHeight: theme.size.lineHeight.S,
        letterSpacing: theme.size.letterSpacing.S,
      },
      subtitle2: {
        fontStyle: 'normal',
        fontWeight: theme.size.fontWeight.bold,
        fontSize: theme.size.fontSize.XS,
        lineHeight: theme.size.lineHeight.XS,
        letterSpacing: theme.size.letterSpacing.XS,
      },
      subtitle3: {
        fontStyle: 'normal',
        fontWeight: theme.size.fontWeight.bold,
        fontSize: theme.size.fontSize.XXS,
        lineHeight: theme.size.lineHeight.XXS,
        letterSpacing: theme.size.letterSpacing.XXS,
      },
      body1: {
        fontStyle: 'normal',
        fontWeight: theme.size.fontWeight.regular,
        fontSize: theme.size.fontSize.S,
        lineHeight: theme.size.lineHeight.S,
        letterSpacing: theme.size.letterSpacing.S,
      },
      body2: {
        fontStyle: 'normal',
        fontWeight: theme.size.fontWeight.regular,
        fontSize: theme.size.fontSize.XS,
        lineHeight: theme.size.lineHeight.XS,
        letterSpacing: theme.size.letterSpacing.XS,
      },
      body3: {
        fontStyle: 'normal',
        fontWeight: theme.size.fontWeight.regular,
        fontSize: theme.size.fontSize.XXS,
        lineHeight: theme.size.lineHeight.XXS,
        letterSpacing: theme.size.letterSpacing.XXS,
      },
      label1: {
        fontStyle: 'normal',
        textTransform: 'uppercase',
        fontWeight: theme.size.fontWeight.regular,
        fontSize: theme.size.fontSize.S,
        lineHeight: theme.size.lineHeight.S,
        letterSpacing: '0.32px',
      },
      label2: {
        fontStyle: 'normal',
        textTransform: 'uppercase',
        fontWeight: theme.size.fontWeight.regular,
        fontSize: theme.size.fontSize.XS,
        lineHeight: theme.size.lineHeight.XS,
        letterSpacing: '0.14px',
      },
      label3: {
        fontStyle: 'normal',
        textTransform: 'uppercase',
        fontWeight: theme.size.fontWeight.regular,
        fontSize: theme.size.fontSize.XXS,
        lineHeight: theme.size.lineHeight.XXS,
      },
      componentsMap: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h6',
        subtitle2: 'h6',
        subtitle3: 'h6',
        body1: 'div',
        body2: 'div',
        body3: 'div',
        label1: 'div',
        label2: 'div',
        label3: 'div',
      },
    },

    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true,
          variant: 'contained',
        },
        styleOverrides: {
          root: (props) => ({
            fontFamily: '"Inter", sans-serif',
            color: props.theme.palette.body.primary,
            ':disabled': {
              backgroundColor: props.theme.palette.backgroundQuaternary,
              color: props.theme.palette.body.placeholder,
              border: 'none',
            },
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '45px',
            boxShadow: 'none !important',
            '& .MuiButton-startIcon': {
              marginLeft: 0,
            },
            '& .MuiButton-endIcon': {
              marginRight: 0,
            },
          }),
          contained: (props) => ({
            color: props.theme.palette.body.tint,
            backgroundColor: props.theme.palette.brand.primary,
            border: '1px solid transparent',
            ':hover': {
              backgroundColor: props.theme.palette.brand.tertiary,
            },
            ':focus': {
              backgroundColor: props.theme.palette.brand.tertiary,
              outline: `2px solid ${props.theme.palette.brand.alpha2}`,
            },
            ':active': {
              backgroundColor: props.theme.palette.brand.quaternary,
              outline: 'none',
            },
          }),
          containedError: (props) => ({
            backgroundColor: props.theme.palette.error.main,
            ':hover': {
              backgroundColor: props.theme.palette.error.tertiary,
            },
            ':focus': {
              backgroundColor: props.theme.palette.error.tertiary,
              outline: `2px solid ${props.theme.palette.error.alpha2}`,
            },
            ':active': {
              backgroundColor: props.theme.palette.error.secondary,
            },
          }),
          outlinedError: (props) => ({
            color: props.theme.palette.error.primary,
          }),
          containedSuccess: (props) => ({
            backgroundColor: props.theme.palette.success.main,
            ':hover': {
              backgroundColor: props.theme.palette.success.tertiary,
            },
            ':focus': {
              backgroundColor: props.theme.palette.success.tertiary,
              outline: `2px solid ${props.theme.palette.success.alpha2}`,
            },
            ':active': {
              backgroundColor: props.theme.palette.success.secondary,
            },
          }),
          outlined: (props) => ({
            color: props.theme.palette.body.primary,
            borderColor: props.theme.palette.border.primary,
            backgroundColor: props.theme.palette.backgroundPrimary,
            ':hover': {
              borderColor: props.theme.palette.border.secondary,
              backgroundColor: props.theme.palette.backgroundSecondary,
            },
            ':focus': {
              borderColor: props.theme.palette.border.tertiary,
              backgroundColor: props.theme.palette.backgroundPrimary,
              outline: `2px solid ${props.theme.palette.backgroundQuaternary}`,
            },
            ':active': {
              borderColor: props.theme.palette.border.tertiary,
              backgroundColor: props.theme.palette.backgroundQuaternary,
              outline: 'none',
            },
            ':disabled': {
              border: '1px solid transparent',
            },
          }),
          sizeLarge: (props) => ({
            padding: `${props.theme.spacing(2)} ${props.theme.spacing(3)}`,
            borderRadius: props.theme.spacing(2),
            letterSpacing: props.theme.size.letterSpacing.S,
            lineHeight: theme.size.lineHeight.S,
            fontSize: theme.size.fontSize.S,
            maxHeight: 40,
          }),
          sizeMedium: (props) => ({
            padding: `${props.theme.spacing(1)} ${props.theme.spacing(2)}`,
            borderRadius: props.theme.spacing(2),
            letterSpacing: props.theme.size.letterSpacing.S,
            lineHeight: theme.size.lineHeight.S,
          }),
          sizeSmall: (props) => ({
            padding: props.theme.spacing(1),
            borderRadius: props.theme.spacing(2),
            fontSize: theme.size.fontSize.XXS,
            lineHeight: theme.size.lineHeight.XXS,
          }),
        },
      },
      MuiLoadingButton: {
        defaultProps: {
          loadingPosition: 'center',
        },
        styleOverrides: {
          root: (props) => ({
            '&.MuiLoadingButton-loading': {
              color: props.theme.palette.body.placeholder,
            },
          }),
          loadingIndicator: (props) => ({
            color: props.theme.palette.brand.primary,
          }),
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: (props) => ({
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: theme.spacing(2),
            marginBottom: 0,
            '& .MuiButtonBase-root': {
              marginLeft: 0,
              borderLeft: '1px solid',
              borderColor: props.theme.palette.border.primary,
              borderRadius: '8px',
              '&:disabled': {
                color: props.theme.palette.body.placeholder,
              },
            },
          }),
        },
      },
      MuiToggleButton: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiMenuItem: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: (props) => ({
            color: props.theme.palette.body.primary,
          }),
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: (props) => ({
            fontFamily: '"Inter", sans-serif',
            position: 'relative',
            fontStyle: 'normal',
            marginBottom: props.theme.spacing(0),
            color: props.theme.palette.body.primary,
            fontWeight: props.theme.size.fontWeight.regular,
            fontSize: props.theme.size.fontSize.XXS,
            lineHeight: props.theme.size.lineHeight.XXS,
            transform: 'none',
            '&.Mui-focused': {
              color: props.theme.palette.brand.primary, // Change color when focused
            },
          }),
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: (props) => ({
            fontFamily: '"Inter", sans-serif',
            fontStyle: 'normal',
            color: props.theme.palette.body.placeholder,
            fontWeight: props.theme.size.fontWeight.regular,
            fontSize: props.theme.size.fontSize.XXS,
            lineHeight: props.theme.size.lineHeight.XXS,
          }),
          error: (props) => ({
            color: props.theme.palette.error.tertiary,
          }),
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: (props) => ({
            fontFamily: '"Inter", sans-serif',
            '&.Mui-disabled': {
              color: `${props.theme.palette.body.placeholder} !important`,
            },
          }),
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: (props) => ({
            borderRadius: '12px',
            border: `1px solid ${props.theme.palette.border.primary}`,
          }),
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: (props) => ({
            fontFamily: '"Inter", sans-serif',
            fontStyle: 'normal',
            fontWeight: props.theme.size.fontWeight.regular,
            lineHeight: props.theme.size.lineHeight.XS,
            fontSize: props.theme.size.fontSize.XS,
            borderColor: props.theme.palette.border.primary,
            borderRight: `1px solid ${props.theme.palette.border.primary}`,
            backgroundColor: props.theme.palette.backgroundSecondary,
            whiteSpace: 'nowrap',
            padding: `
              ${props.theme.spacing(2)}
              ${props.theme.spacing(2)}
              ${props.theme.spacing(2)} 
              ${props.theme.spacing(4)}
            `,
          }),
          body: (props) => ({
            backgroundColor: props.theme.palette.backgroundPrimary,
            padding: `${props.theme.spacing(3)} ${props.theme.spacing(4)}`,
            color: props.theme.palette.body.primary,
          }),
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: (props) => ({
            '&.MuiTableRow-hover:hover .MuiTableCell-root': {
              backgroundColor: props.theme.palette.backgroundTertiary,
            },
          }),
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: (props) => ({
            color: props.theme.palette.body.tertiary,
            width: '100%',
            '&.Mui-active': {
              color: props.theme.palette.body.tertiary,

              '& .MuiTableSortLabel-icon': {
                fill: props.theme.palette.body.primary,
              },
            },
            '& .MuiTableSortLabel-icon': {
              marginRight: 0,
              opacity: 1,
            },
            '&:hover, &:focus': {
              color: props.theme.palette.body.tertiary,
            },
          }),
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: (props) => ({
            padding: props.theme.spacing(1),
            border: '1px solid',
            borderColor: props.theme.palette.border.primary,
            borderRadius: '12px',

            '&.lineView': {
              border: ' none',
              minHeight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              borderRadius: 0,
              marginBottom: '-1px',
              '& .MuiTab-root': {
                paddingLeft: '0px',
                paddingRight: '0px',
              },
              '& .MuiTabs-indicator': {
                display: 'block',
                backgroundColor: theme.palette.brand.primary,
              },
            },
          }),
          indicator: {
            display: 'none',
          },
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: (props) => ({
            fontFamily: '"Inter", sans-serif',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '45px',
            minHeight: '30px',
            boxShadow: 'none !important',
            padding: `${props.theme.spacing(2)} ${props.theme.spacing(4)}`,
            borderRadius: props.theme.spacing(2),
            letterSpacing: props.theme.size.letterSpacing.S,
            lineHeight: theme.size.lineHeight.S,

            color: props.theme.palette.body.primary,
            border: `1px solid ${props.theme.palette.border.primary}`,

            '&:not(:last-child)': {
              marginRight: props.theme.spacing(1),
            },

            '&.Mui-selected': {
              color: props.theme.palette.body.tint,
              backgroundColor: props.theme.palette.brand.primary,
              border: '1px solid transparent',
            },

            '&.Mui-disabled': {
              color: props.theme.palette.body.placeholder,
              backgroundColor: props.theme.palette.backgroundSecondary,
            },

            '&.lineView': {
              minWidth: 'inherit',
              minHeight: 'inherit',
              borderRadius: 'inherit',
              border: 'inherit',
              padding: `0 ${theme.spacing(0)}`,
              paddingBottom: theme.spacing(1),
              color: theme.palette.body.tertiary,

              '&:not(:last-child)': {
                marginRight: theme.spacing(4),
              },

              '&.Mui-selected': {
                color: theme.palette.brand.primary,
                backgroundColor: 'inherit',
                border: 'inherit',
              },
            },
          }),
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
            maxHeight: 415,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 'auto',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: (props) => ({
            '&.MuiMenu-paper': {
              marginTop: 3,
              backgroundColor: props.theme.palette.backgroundSecondary,
              boxShadow: 'none',
              border: `1px solid ${props.theme.palette.border.primary}`,
              borderRadius: '8px',
              backgroundImage: 'none',
            },
            '&.MuiPopover-paper': {
              backgroundImage: 'none',
            },
          }),
        },
      },
      MuiTooltip: {
        defaultProps: {
          placement: 'bottom-start',
          disableFocusListener: true,
          disableHoverListener: true,
          disableTouchListener: true,
          slotProps: {
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -7],
                  },
                },
              ],
            },
          },
        },
        styleOverrides: {
          tooltip: (props) => ({
            backgroundColor: props.theme.palette.backgroundSecondary,
            border: `1px solid ${props.theme.palette.border.primary}`,
            color: theme.palette.body.primary,
            borderRadius: '8px',
          }),
        },
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: (props) => ({
            padding: '8px',
            '& .MuiSvgIcon-root': {
              fill: props.theme.palette.brand.primary,
            },
            '&.Mui-disabled': {
              '& svg rect': {
                fill: props.theme.palette.backgroundQuaternary,
                stroke: props.theme.palette.backgroundQuaternary,
              },
              '& svg path': {
                fill: `${props.theme.palette.body.placeholder} !important`,
              },
            },
          }),
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: (props) => ({
            '&.Mui-disabled': {
              color: `${props.theme.palette.body.placeholder} !important`,
            },
          }),
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: (props) => ({
            color: props.theme.palette.body.primary,
          }),
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: (props) => ({
            padding: props.theme.spacing(4),
            backgroundColor: props.theme.palette.backgroundPrimary,
          }),
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: (props) => ({
            padding: props.theme.spacing(4),
            borderTop: `1px solid ${props.theme.palette.border.primary}`,
            backgroundColor: props.theme.palette.backgroundSecondary,
          }),
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: (props) => ({
            padding: props.theme.spacing(1),
          }),
        },
      },
    },
  });

  return theme;
};

export default getTheme;
