import {LoadingButton} from '@mui/lab';
import {Box, Button, TableRow, useTheme} from '@mui/material';
import {FormikErrors} from 'formik';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DeleteIcon} from '../../../assets/icons/delete.svg';
import Typography from '../../../components/UI/Typography';
import {DNSRecordKeyType, IDNSRecord, RecordType, updateDnsRecord} from '../../../stores/Domain/DnsRecordsStore';
import {DomainRecordTableCell} from './DomainRecordsTable';
import DomainRecordTableForm, {IDomainRecordTableFormRef} from './DomainRecordTableForm';
import {DomainRecordColumn} from './types';

export interface IDomainRecordsTableRowRef {
  resetForm(): void;
  setFormErrors(errors: FormikErrors<RecordType>): void;
}

interface IProps {
  recordKey: DNSRecordKeyType;
  dnsRecord: IDNSRecord;
  columns: DomainRecordColumn[];
  onDelete?(record: IDNSRecord): Promise<void>;
  onSubmit?(record: IDNSRecord): Promise<void>;
  canDelete?: boolean;
  canEdit?: boolean;
  isInitialNsRecords?: boolean;
}

export const DomainRecordsTableRow = React.forwardRef(function DomainRecordsTableRow(
  props: IProps,
  ref: React.Ref<IDomainRecordsTableRowRef>,
) {
  const {t} = useTranslation();
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const [showForm, setShowForm] = React.useState<boolean>(props.isInitialNsRecords || false);
  const [pendingErrors, setPendingErrors] = React.useState<FormikErrors<RecordType> | null>(null);

  const domainRecordFormRef = React.useRef<IDomainRecordTableFormRef>(null);

  const handleEdit = () => {
    setShowForm(true);
  };

  const handleCancel = () => {
    setShowForm(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    await props.onDelete?.(props.dnsRecord);
    setLoading(false);
  };

  const handleSubmit = async (record: RecordType) => {
    await props.onSubmit?.(updateDnsRecord(props.dnsRecord, record));
  };

  React.useEffect(() => {
    if (showForm && pendingErrors) {
      domainRecordFormRef.current?.setErrors(pendingErrors);
      setPendingErrors(null);
    }
  }, [showForm, pendingErrors]);

  React.useImperativeHandle(ref, () => ({
    resetForm: () => {
      domainRecordFormRef.current?.reset();
      setShowForm(false);
    },
    setFormErrors: (errors: FormikErrors<RecordType>) => {
      setShowForm(true);
      setPendingErrors(errors);
    },
  }));

  const record = props.dnsRecord[props.recordKey] as RecordType;

  if (!record) {
    return null;
  }

  if (showForm) {
    return (
      <DomainRecordTableForm
        ref={domainRecordFormRef}
        record={record}
        columns={props.columns}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    );
  }

  return (
    <TableRow hover>
      {props.columns.map((column, idx) => {
        if (props.columns.length - 1 === idx) {
          return (
            <DomainRecordTableCell sx={{paddingLeft: `calc(${theme.spacing(4)} + 5px)`}} key={String(column.name)}>
              <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography>{record[column.name]}</Typography>
                <div>
                  {props.canEdit ? (
                    <Button variant="outlined" size="large" sx={{marginRight: theme.spacing(2)}} onClick={handleEdit}>
                      {t('action_button_edit')}
                    </Button>
                  ) : null}

                  {props.canDelete ? (
                    <LoadingButton
                      loading={loading}
                      variant="outlined"
                      size="large"
                      sx={{minWidth: 0, paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2)}}
                      onClick={handleDelete}
                    >
                      <DeleteIcon style={{fill: theme.palette.error.primary}} />
                    </LoadingButton>
                  ) : null}
                </div>
              </Box>
            </DomainRecordTableCell>
          );
        }

        return (
          <DomainRecordTableCell sx={{paddingLeft: `calc(${theme.spacing(4)} + 5px)`}} key={String(column.name)}>
            <Typography>{record[column.name]}</Typography>
          </DomainRecordTableCell>
        );
      })}
    </TableRow>
  );
});

export default DomainRecordsTableRow;
