import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import DomainSuggestion from '../../stores/Domain/DomainSuggestion';
import {formatTimestamp} from '../../utils/format/format';

interface IProps {
  domainSuggestion: DomainSuggestion;
}

const RenewDomainSummary: React.FC<IProps> = ({domainSuggestion}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() + 1);
  const newExpirationAtTimestamp = currentDate.getTime();

  return (
    <Box
      sx={{
        marginBottom: theme.spacing(4),
        padding: theme.spacing(4),
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: '12px',
      }}
    >
      <Typography component="div" variant="label3" color="body.tertiary" gutterBottom>
        {t('component_payment_viewer_domain_renew_summary_title')}
      </Typography>

      <Typography variant="body3" color="body.placeholder">
        {t('component_payment_viewer_domain_renew_summary_name_label')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {domainSuggestion?.name}
      </Typography>

      <Typography variant="body3" color="body.placeholder">
        {t('component_payment_viewer_domain_renew_period_label')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        1 year
      </Typography>

      <Typography variant="body3" color="body.placeholder">
        {t('component_payment_viewer_domain_new_expiration_date_label')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {formatTimestamp(newExpirationAtTimestamp, false)}
      </Typography>

      <Typography variant="body3" color="body.placeholder">
        {t('component_payment_viewer_domain_renew_fee_label')}
      </Typography>
      <Typography variant="h4" gutterBottom>
        {domainSuggestion.renewalPriceFormatter.formatted}
      </Typography>
    </Box>
  );
};

export default RenewDomainSummary;
