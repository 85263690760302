import {Box, TableCell, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {ReactComponent as FavoriteStrokeIcon} from '../../../assets/icons/favorite-stroke.svg';
import {ReactComponent as FavoriteIcon} from '../../../assets/icons/favorite.svg';
import ProviderLogo from '../../../components/ProviderLogo';
import RegionLabel from '../../../components/RegionLabel';
import CopyableBox, {ICopyableBoxRef} from '../../../components/UI/CopyableBox';
import Typography from '../../../components/UI/Typography';
import {navigateToServerInfo} from '../../../routes/navigateTo';
import {Instance} from '../../../stores/Instance';
import ServerStatusDropdown from '../ServerStatusDropdown';
import {ACTIONS_COLUMN_WIDTH, Column, ColumnType} from '../useServersTableColumns';
import ServerActionsDropdown from './ServerActionsDropdown';

interface IProps {
  instance: Instance;
  columns: Column[];
}

export const ServerTableRow: React.FC<IProps> = observer(({instance, columns}) => {
  const theme = useTheme();

  const copyableIpv4BoxRef = React.useRef<ICopyableBoxRef>(null);
  const copyableIpv6BoxRef = React.useRef<ICopyableBoxRef>(null);
  const copyableRootPasswordBoxRef = React.useRef<ICopyableBoxRef>(null);

  const handleToggleAddToFavorites = (e) => {
    e.stopPropagation();

    instance.toggleAddToFavorites();
  };

  const getTableCellComponent = (columnId: ColumnType) => {
    switch (columnId) {
      case ColumnType.Hostname:
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {instance?.isImportant ? (
              <FavoriteIcon
                style={{
                  marginRight: theme.spacing(2),
                  minWidth: 24,
                  fill: theme.palette.warning.primary,
                  cursor: 'pointer',
                }}
                onClick={handleToggleAddToFavorites}
              />
            ) : (
              <FavoriteStrokeIcon
                style={{
                  marginRight: theme.spacing(2),
                  minWidth: 24,
                  fill: theme.palette.border.primary,
                  cursor: 'pointer',
                }}
                onClick={handleToggleAddToFavorites}
              />
            )}
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {instance.name}
            </Box>
          </Box>
        );
      case ColumnType.Image:
        return instance?.image?.name || `No image [instance_id = ${instance.id}]`;
      case ColumnType.Datacenter:
        return instance.datacenter ? (
          <RegionLabel regionOrDatacenter={instance.datacenter} />
        ) : (
          `No datacenter [instance_id = ${instance.id}]`
        );
      case ColumnType.Host:
        return (
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <ProviderLogo providerId={instance.providerId} />
            <Typography sx={{marginLeft: theme.spacing(1)}} variant="body2">
              {instance.provider?.title}
            </Typography>
          </Box>
        );
      case ColumnType.Status:
        return <ServerStatusDropdown instance={instance} />;
      case ColumnType.IPv4:
        return <CopyableBox ref={copyableIpv4BoxRef} value={instance.ipv4} />;
      case ColumnType.IPv6:
        return <CopyableBox ref={copyableIpv6BoxRef} value={instance.ipv6} />;
      case ColumnType.RootPassword:
        return <CopyableBox ref={copyableRootPasswordBoxRef} maskedValue value={instance.rootPassword} />;
    }
  };

  const handleMouseEnter = () => {
    copyableIpv4BoxRef.current?.showButtons();
    copyableIpv6BoxRef.current?.showButtons();
    copyableRootPasswordBoxRef.current?.showButtons();
  };

  const handleMouseLeave = () => {
    copyableIpv4BoxRef.current?.hideButtons();
    copyableIpv6BoxRef.current?.hideButtons();
    copyableRootPasswordBoxRef.current?.hideButtons();
  };

  const handleClickTableRow = (columnType: ColumnType) => {
    switch (columnType) {
      case ColumnType.IPv4:
        copyableIpv4BoxRef.current?.copyToClipboard();
        return;
      case ColumnType.IPv6:
        copyableIpv6BoxRef.current?.copyToClipboard();
        return;
      case ColumnType.RootPassword:
        copyableRootPasswordBoxRef.current?.copyToClipboard();
        return;
    }

    navigateToServerInfo(instance);
  };

  return (
    <React.Fragment>
      {columns.map((column) =>
        column.visible ? (
          <TableCell
            key={column.id}
            align={column.align}
            sx={{
              minWidth: column.minWidth,
              width: column.width,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClickTableRow(column.id)}
          >
            {getTableCellComponent(column.id)}
          </TableCell>
        ) : null,
      )}
      <TableCell
        style={{
          width: ACTIONS_COLUMN_WIDTH,
          position: 'relative',
          borderRight: 'none',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ServerActionsDropdown instance={instance} />
      </TableCell>
    </React.Fragment>
  );
});

export default ServerTableRow;
