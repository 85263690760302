import i18n from '../i18n';
import {ca2, ca2auth, ca2billing, ca2domains, ca2instances, ca2ssh, ca2users} from './proto';

const {t} = i18n;

export function getApiServerError(err: ca2.ServerError): string {
  const errors = {
    [ca2.ServerError.SE_EXPIRED_TOKEN_ERROR]: t('api_error_server_expired_token'),
    [ca2.ServerError.SE_INTERNAL_SERVER_ERROR]: t('api_error_server_internal'),
    [ca2.ServerError.SE_INVALID_TOKEN_ERROR]: t('api_error_server_invalid_token'),
    [ca2.ServerError.SE_INVALID_TRX_ERROR]: t('api_error_server_invalid_trx'),
    [ca2.ServerError.SE_TOKEN_REQUIRED_ERROR]: t('api_error_server_token_required'),
    [ca2.ServerError.SE_TRX_REQUIRED_ERROR]: t('api_error_server_trx_required'),
    [ca2.ServerError.SE_UNKNOWN_ERROR]: t('api_error_server_unknown'),
  };

  return errors[err] || t('api_server_unknown');
}

export function getApiAuthStartError(err: ca2auth.StartError): string {
  const errors = {
    [ca2auth.StartError.SE_UNKNOWN_ERROR]: t('api_error_auth_start_unknown_error'),
    [ca2auth.StartError.SE_INVALID_EMAIL_ERROR]: t('api_error_auth_start_invalid_email'),
    [ca2auth.StartError.SE_INVALID_RECAPTCHA_ERROR]: t('api_error_auth_start_invalid_recaptcha'),
    [ca2auth.StartError.SE_INVALID_REFERRAL_CODE_ERROR]: t('api_error_auth_start_invalid_referral_code'),
    [ca2auth.StartError.SE_INVALID_BEARER_JWT_TOKEN_ERROR]: t('api_error_auth_start_invalid_jwt_token'),
    [ca2auth.StartError.SE_USER_IS_BLOCKED_ERROR]: t('api_error_auth_start_user_is_blocked'),
  };

  return errors[err] || t('api_error_auth_start_unknown_error');
}

export function getApiOAuthError(err: ca2auth.OAuthError): string {
  const errors = {
    [ca2auth.OAuthError.OA_EMAIL_IS_NOT_ASSOCIATED]: t('api_error_oauth_email_is_not_associated_error'),
    [ca2auth.OAuthError.OA_INVALID_EMAIL_ERROR]: t('api_error_oauth_invalid_email_error'),
    [ca2auth.OAuthError.OA_INVALID_REF_ID_PROVIDED]: t('api_error_oauth_invalid_ref_id_error'),
    [ca2auth.OAuthError.OA_REGISTRATION_TEMPORARILY_DISABLED_ERROR]: t(
      'api_error_oauth_registration_temporarily_disabled_error',
    ),
    [ca2auth.OAuthError.OA_USER_IS_BLOCKED_ERROR]: t('api_error_oauth_user_is_blocked_error'),
    [ca2auth.OAuthError.OA_UNKNOWN_ERROR]: t('api_error_oauth_unknown_error'),
  };

  return errors[err] || t('api_error_oauth_unknown_error');
}

export function getApiAuthEnterCodeError(err: ca2auth.EnterCodeError): string {
  const errors = {
    [ca2auth.EnterCodeError.ECE_UNKNOWN_ERROR]: t('api_error_auth_enter_code_unknown_error'),
    [ca2auth.EnterCodeError.ECE_INVALID_EMAIL_ERROR]: t('api_error_auth_enter_code_invalid_email'),
    [ca2auth.EnterCodeError.ECE_INVALID_CODE_ERROR]: t('api_error_auth_enter_code_invalid_code'),
    [ca2auth.EnterCodeError.ECE_CODE_EXPIRED_ERROR]: t('api_error_auth_enter_code_expired_code'),
    [ca2auth.EnterCodeError.ECE_USER_NOT_FOUND_ERROR]: t('api_error_auth_enter_code_user_not_found'),
    [ca2auth.EnterCodeError.ECE_TOO_MANY_ATTEMPTS_ERROR]: t('api_error_auth_enter_code_too_many_attempts'),
  };

  return errors[err] || t('api_error_auth_enter_code_unknown_error');
}

export function getApiAuthEnterPasswordError(err: ca2auth.EnterPasswordError): string {
  const errors = {
    [ca2auth.EnterPasswordError.EPE_UNKNOWN_ERROR]: t('api_error_auth_enter_password_unknown_error'),
    [ca2auth.EnterPasswordError.EPE_INVALID_EMAIL_ERROR]: t('api_error_auth_enter_password_invalid_email'),
    [ca2auth.EnterPasswordError.EPE_INVALID_PASSWORD_ERROR]: t('api_error_auth_enter_password_invalid_password'),
  };

  return errors[err] || t('api_error_auth_enter_code_unknown_error');
}

export function getApiAddSshKeyError(err: ca2ssh.AddSSHKeyError): string {
  const errors = {
    [ca2ssh.AddSSHKeyError.ASE_EMPTY_NAME_ERROR]: t('api_error_add_ssh_key_empty_name'),
    [ca2ssh.AddSSHKeyError.ASE_INVALID_PUBLIC_KEY_ERROR]: t('api_error_add_ssh_key_invalid_public_key'),
    [ca2ssh.AddSSHKeyError.ASE_UNKNOWN_ERROR]: t('api_error_add_ssh_key_unknown_error'),
  };

  return errors[err] || t('api_error_add_ssh_key_unknown_error');
}

export function getApiUpdateSshKeyError(err: ca2ssh.UpdateSSHKeyError): string {
  const errors = {
    [ca2ssh.UpdateSSHKeyError.USE_EMPTY_NAME_ERROR]: t('api_error_add_ssh_key_empty_name'),
    [ca2ssh.UpdateSSHKeyError.USE_INVALID_PUBLIC_KEY_ERROR]: t('api_error_add_ssh_key_invalid_public_key'),
    [ca2ssh.UpdateSSHKeyError.USE_INVALID_ID_ERROR]: t('api_error_add_ssh_key_invalid_id'),
    [ca2ssh.UpdateSSHKeyError.USE_UNKNOWN_ERROR]: t('api_error_add_ssh_key_unknown_error'),
  };

  return errors[err] || t('api_error_add_ssh_key_unknown_error');
}

export function getApiDeleteSshKeyError(err: ca2ssh.DeleteSSHKeyError): string {
  const errors = {
    [ca2ssh.DeleteSSHKeyError.DSE_INVALID_ID_ERROR]: t('api_error_add_ssh_key_empty_name'),
    [ca2ssh.DeleteSSHKeyError.DSE_KEY_USED_ERROR]: t('api_error_delete_ssh_key_used_error'),
    [ca2ssh.DeleteSSHKeyError.DSE_UNKNOWN_ERROR]: t('api_error_add_ssh_key_unknown_error'),
  };

  return errors[err] || t('api_error_add_ssh_key_unknown_error');
}

export function getApiCreateInstanceError(err: ca2instances.CreateInstanceError): string {
  const errors = {
    [ca2instances.CreateInstanceError.CSE_INVALID_IMAGE_ID]: t('api_error_create_instance_invalid_image_id'),
    [ca2instances.CreateInstanceError.CSE_INVALID_INITIAL_SETUP_SCRIPT]: t(
      'api_error_create_instance_invalid_initial_setup_script',
    ),
    [ca2instances.CreateInstanceError.CSE_INVALID_PLAN_ID]: t('api_error_create_instance_invalid_plan_id'),
    [ca2instances.CreateInstanceError.CSE_INVALID_PROMO_CODE]: t('api_error_create_instance_invalid_promo_code'),
    [ca2instances.CreateInstanceError.CSE_INVALID_DATACENTER_ID]: t('api_error_create_instance_invalid_region_id'),
    [ca2instances.CreateInstanceError.CSE_ROOT_PASSWORD_OR_SSH_KEYS_REQUIRED]: t(
      'api_error_create_instance_password_or_key_required',
    ),
    [ca2instances.CreateInstanceError.CSE_INVALID_SSH_KEY_ID]: t('api_error_create_instance_invalid_ssh_key_id'),
    [ca2instances.CreateInstanceError.CSE_NOT_ENOUGH_BALANCE]: t('api_error_create_instance_not_enough_balance'),
    [ca2instances.CreateInstanceError.CSE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS]: t(
      'api_error_create_instance_server_name_contains_invalid_chars',
    ),
    [ca2instances.CreateInstanceError.CSE_PASSWORD_NOT_STRONG]: t('api_error_create_instance_password_not_strong'),
    [ca2instances.CreateInstanceError.CSE_INVALID_WINDOWS_USERNAME]: t(
      'api_error_create_instance_invalid_windows_username',
    ),
    [ca2instances.CreateInstanceError.CSE_SERVER_NAME_TOO_LONG]: t('api_error_create_instance_server_name_too_long'),
    [ca2instances.CreateInstanceError.CSE_PASSWORD_NOT_ALLOWED_FOR_THIS_IMAGE]: t(
      'api_error_create_instance_password_not_allowed_for_this_image',
    ),
    [ca2instances.CreateInstanceError.CSE_UNKNOWN]: t('api_error_create_instance_unknown_error'),
  };

  return errors[err] || t('api_error_create_instance_unknown_error');
}

export function getApiUpTopUpBalanceError(err: ca2billing.TopUpBalanceError): string {
  const errors = {
    [ca2billing.TopUpBalanceError.TUBE_AMOUNT_TOO_SMALL]: t('api_error_top_up_balance_amount_too_small'),
    [ca2billing.TopUpBalanceError.TUBE_INVALID_CURRENCY_CODE]: t('api_error_top_up_balance_invalid_currency_code'),
    [ca2billing.TopUpBalanceError.TUBE_INVALID_PAYMENT_SYSTEM]: t('api_error_top_up_balance_invalid_payment_system'),
    [ca2billing.TopUpBalanceError.TUBE_UNKNOWN_ERROR]: t('api_error_top_up_balance_unknown_error'),
  };

  return errors[err] || t('api_error_top_up_balance_unknown_error');
}

export function getApiInstanceOperationError(err: ca2instances.InstanceOperationError): string {
  const errors = {
    [ca2instances.InstanceOperationError.IOP_INSTANCE_NOT_FOUND]: t('api_error_instance_operation_not_found_error'),
    [ca2instances.InstanceOperationError.IOP_NOT_ALLOWED]: t('api_error_instance_operation_not_allowed_error'),
    [ca2instances.InstanceOperationError.IOP_BALANCE_IS_LESS_THAN_ZERO]: t(
      'api_error_instance_operation_balance_less_than_zero',
    ),
    [ca2instances.InstanceOperationError.IOP_UNKNOWN]: t('api_error_instance_operation_unknown_error'),
  };

  return errors[err] || t('api_error_instance_operation_unknown_error');
}

export function getApiInstanceUpdateError(err: ca2instances.UpdateInstanceError): string {
  const errors = {
    [ca2instances.UpdateInstanceError.UIE_INSTANCE_NOT_FOUND]: t('api_error_update_instance_not_found_error'),
    [ca2instances.UpdateInstanceError.UIE_SERVER_NAME_CONTAINS_INVALID_CHARACTERS]: t(
      'api_error_update_instance_name_contains_invalid_chars_error',
    ),
    [ca2instances.UpdateInstanceError.UIE_SERVER_NAME_TOO_LONG]: t('api_error_update_instance_name_too_long_error'),
    [ca2instances.UpdateInstanceError.UIE_UNKNOWN]: t('api_error_update_instance_unknown_error'),
  };

  return errors[err] || t('api_error_update_instance_unknown_error');
}

export function getApiCheckDomainError(err: ca2domains.CheckDomainError): string {
  const errors = {
    [ca2domains.CheckDomainError.CDN_INVALID_DOMAIN]: t('api_error_check_domain_invalid_domain'),
    [ca2domains.CheckDomainError.CDN_UNKNOWN]: t('api_error_check_domain_unknown_error'),
  };

  return errors[err] || t('api_error_check_domain_unknown_error');
}

export function getApiRegisterDomainError(err: ca2domains.RegisterDomainError): string {
  const errors = {
    [ca2domains.RegisterDomainError.RDE_INVALID_EXISTING_CONTACT_ID]: t(
      'api_error_register_domain_invalid_existing_contact',
    ),
    [ca2domains.RegisterDomainError.RDE_INVALID_NEW_CONTACT]: t('api_error_register_domain_invalid_new_contact'),
    [ca2domains.RegisterDomainError.RDE_INVALID_OR_NOT_AVAILABLE_DOMAIN]: t(
      'api_error_register_domain_invalid_or_not_available_domain',
    ),
    [ca2domains.RegisterDomainError.RDE_INVALID_TERM_YEARS]: t('api_error_register_domain_invalid_term_years'),
    [ca2domains.RegisterDomainError.RDE_NOT_ENOUGH_BALANCE]: t('api_error_register_domain_not_enough_balance'),
    [ca2domains.RegisterDomainError.RDE_EXISTING_CONTACT_SHOULD_BE_FIXED]: t(
      'api_error_register_domain_existing_contact_should_be_fixed',
    ),
    [ca2domains.RegisterDomainError.RDE_UNKNOWN]: t('api_error_register_domain_unknown_error'),
  };

  return errors[err] || t('api_error_register_domain_unknown_error');
}

export function getApiRenewDomainError(err: ca2domains.RenewDomainError): string {
  const errors = {
    [ca2domains.RenewDomainError.RNE_INVALID_DOMAIN_ID]: t('api_error_domain_renew_invalid_domain_id'),
    [ca2domains.RenewDomainError.RNE_INVALID_TERM_YEARS]: t('api_error_domain_renew_invalid_term_years'),
    [ca2domains.RenewDomainError.RNE_NOT_ENOUGH_BALANCE]: t('api_error_domain_renew_not_enough_balance'),
    [ca2domains.RenewDomainError.RNE_RENEWAL_NOT_ALLOWED]: t('api_error_domain_auto_renew_not_allowed'),
    [ca2domains.RenewDomainError.RNE_UNKNOWN]: t('api_error_domain_renew_unknown_error'),
  };

  return errors[err] || t('api_error_domain_renew_unknown_error');
}

export function getApiDNSRecordCreateOrUpdateError(err: ca2domains.DNSRecordCreateOrUpdateError): string {
  const errors = {
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_UNKNOWN]: t('api_error_domain_record_unknown_error'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_DOMAIN_ID]: t('api_error_domain_record_invalid_domain_id'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_RECORD_ID]: t('api_error_domain_record_invalid_record_id'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_RECORD_TYPE]: t('api_error_domain_record_invalid_record_type'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_TTL]: t('api_error_domain_record_invalid_ttl'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_A_HOSTNAME]: t('api_error_domain_record_invalid_a_hostname'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_A_IPV4]: t('api_error_domain_record_invalid_a_ipv4'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_AAAA_HOSTNAME]: t(
      'api_error_domain_record_invalid_aaaa_hostname',
    ),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_AAAA_IPV6]: t('api_error_domain_record_invalid_aaaa_ipv6'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_CNAME_HOSTNAME]: t(
      'api_error_domain_record_invalid_cname_hostname',
    ),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_CNAME_ALIAS]: t('api_error_domain_record_invalid_cname_alias'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_MX_PRIORITY]: t('api_error_domain_record_invalid_mx_priority'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_MX_MAIL_SERVER]: t(
      'api_error_domain_record_invalid_mx_mail_server',
    ),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_MX_SUBDOMAIN]: t(
      'api_error_domain_record_invalid_mx_subdomain',
    ),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_TXT_HOSTNAME]: t(
      'api_error_domain_record_invalid_txt_hostname',
    ),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_TXT_TEXT]: t('api_error_domain_record_invalid_txt_text'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_SRV_SERVICE]: t('api_error_domain_record_invalid_srv_service'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_SRV_PROTOCOL]: t(
      'api_error_domain_record_invalid_srv_protocol',
    ),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_SRV_PRIORITY]: t(
      'api_error_domain_record_invalid_srv_priority',
    ),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_SRV_WEIGHT]: t('api_error_domain_record_invalid_srv_weight'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_SRV_PORT]: t('api_error_domain_record_invalid_srv_port'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_SRV_TARGET]: t('api_error_domain_record_invalid_srv_target'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_CAA_FLAG]: t('api_error_domain_record_invalid_caa_flag'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_CAA_TAG]: t('api_error_domain_record_invalid_caa_tag'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_INVALID_CAA_VALUE]: t('api_error_domain_record_invalid_caa_value'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_RECORD_CONFLICT]: t('api_error_domain_record_conflict'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_RECORD_LIMIT_EXCEEDED]: t('api_error_domain_record_limit_exceeded'),
    [ca2domains.DNSRecordCreateOrUpdateError.DRT_REQUIRED_DEFAULT_DOMAIN_NAME_SERVERS]: t(
      'api_error_domain_record_required_domain_name_servers',
    ),
  };

  return errors[err] || t('api_error_domain_record_unknown_error');
}

export function getApiDNSRecordDeleteError(err: ca2domains.DNSRecordDeleteError): string {
  const errors = {
    [ca2domains.DNSRecordDeleteError.DRD_UNKNOWN]: t('api_error_domain_record_delete_unknown_error'),
    [ca2domains.DNSRecordDeleteError.DRD_INVALID_DOMAIN_ID]: t('api_error_domain_record_delete_invalid_domain_id'),
    [ca2domains.DNSRecordDeleteError.DRD_INVALID_RECORD_ID]: t('api_error_domain_record_delete_invalid_record_id'),
  };

  return errors[err] || t('api_error_domain_record_delete_unknown_error');
}

export function getApiDomainContactError(err: ca2domains.ContactError): string {
  const errors = {
    [ca2domains.ContactError.COCE_EMAIL_ADDRESS_REQUIRED]: t('api_error_domain_contact_email_required'),
    [ca2domains.ContactError.COCE_FIRST_NAME_REQUIRED]: t('api_error_domain_contact_first_name_required'),
    [ca2domains.ContactError.COCE_INVALID_CONTACT_ID]: t('api_error_domain_contact_invalid_contact_id'),
    [ca2domains.ContactError.COCE_LAST_NAME_REQUIRED]: t('api_error_domain_contact_last_name_required'),
    [ca2domains.ContactError.COCE_MAILING_ADDRESS_REQUIRED]: t('api_error_domain_contact_address_required'),
    [ca2domains.ContactError.COCE_MAILING_CITY_REQUIRED]: t('api_error_domain_contact_city_required'),
    [ca2domains.ContactError.COCE_MAILING_COUNTRY_CODE_REQUIRED]: t('api_error_domain_contact_country_code_required'),
    [ca2domains.ContactError.COCE_MAILING_STATE_REQUIRED]: t('api_error_domain_contact_state_required'),
    [ca2domains.ContactError.COCE_MAILING_ZIP_REQUIRED]: t('api_error_domain_contact_zip_code_required'),
    [ca2domains.ContactError.COCE_PHONE_NUMBER_REQUIRED]: t('api_error_domain_contact_phone_number_required'),
    [ca2domains.ContactError.COCE_EMAIL_ADDRESS_INVALID]: t('api_error_domain_contact_email_invalid'),
    [ca2domains.ContactError.COCE_PHONE_NUMBER_INVALID]: t('api_error_domain_contact_phone_number_invalid'),
    [ca2domains.ContactError.COCE_FIRST_NAME_SHOULD_CONTAIN_ONLY_ASCII_SYMBOLS]: t(
      'api_error_domain_contact_first_name_only_ascii_symbols_error',
    ),
    [ca2domains.ContactError.COCE_LAST_NAME_SHOULD_CONTAIN_ONLY_ASCII_SYMBOLS]: t(
      'api_error_domain_contact_last_name_only_ascii_symbols_error',
    ),
    [ca2domains.ContactError.COCE_MAILING_ADDRESS_SHOULD_CONTAIN_ONLY_ASCII_SYMBOLS]: t(
      'api_error_domain_contact_email_only_ascii_symbols_error',
    ),
    [ca2domains.ContactError.COCE_MAILING_CITY_SHOULD_CONTAIN_ONLY_ASCII_SYMBOLS]: t(
      'api_error_domain_contact_city_only_ascii_symbols_error',
    ),
    [ca2domains.ContactError.COCE_MAILING_STATE_SHOULD_CONTAIN_ONLY_ASCII_SYMBOLS]: t(
      'api_error_domain_contacts_state_only_ascii_symbols_error',
    ),
    [ca2domains.ContactError.COCE_UNKNOWN]: t('api_error_domain_contact_unknown_error'),
  };

  return errors[err] || t('api_error_domain_contact_unknown_error');
}

export function getApiChangeDomainContactError(err: ca2domains.ChangeDomainContactError): string {
  const errors = {
    [ca2domains.ChangeDomainContactError.CDCE_EXISTING_CONTACT_SHOULD_BE_FIXED]: t(
      'api_error_domain_change_contact_exist_contact_should_be_fixed_error',
    ),
    [ca2domains.ChangeDomainContactError.CDCE_INVALID_DOMAIN_ID]: t(
      'api_error_domain_change_contact_invalid_domain_id',
    ),
    [ca2domains.ChangeDomainContactError.CDCE_INVALID_EXISTING_CONTACT_ID]: t(
      'api_error_domain_change_contact_invalid_existing_contact_id',
    ),
    [ca2domains.ChangeDomainContactError.CDCE_UNKNOWN]: t('api_error_domain_change_contact_unknown_error'),
  };

  return errors[err] || t('api_error_domain_change_contact_unknown_error');
}

export function getApiChangeDomainNameServersError(err: ca2domains.ChangeDomainNameServersError): string {
  const errors = {
    [ca2domains.ChangeDomainNameServersError.CDNSE_INVALID_DOMAIN_ID]: t(
      'api_error_domain_name_servers_invalid_domain_id',
    ),
    [ca2domains.ChangeDomainNameServersError.CDNSE_INVALID_NAME_SERVERS]: t(
      'api_error_domain_name_servers_invalid_name_servers',
    ),
    [ca2domains.ChangeDomainNameServersError.CDNSE_NAME_SERVERS_SHOULD_BE_AT_LEAST_2]: t(
      'api_error_domain_name_servers_should_be_at_least_2',
    ),
    [ca2domains.ChangeDomainNameServersError.CDNSE_NAME_SERVERS_SHOULD_BE_NO_MORE_THAN_13]: t(
      'api_error_domain_name_servers_should_be_no_more_than_13',
    ),
    [ca2domains.ChangeDomainNameServersError.CDNSE_UNKNOWN]: t('api_error_domain_name_servers_unknown_error'),
  };

  return errors[err] || t('api_error_domain_name_servers_unknown_error');
}

export function getApiDomainContactDeleteError(err: ca2domains.DeleteContactError): string {
  const errors = {
    [ca2domains.DeleteContactError.DCE_CONTACT_NOT_FOUND]: t('api_error_domain_delete_contact_not_found_error'),
    [ca2domains.DeleteContactError.DCE_CONTACT_USED]: t('api_error_domain_delete_contact_contact_used_error'),
    [ca2domains.DeleteContactError.DCE_UNKNOWN]: t('api_error_domain_delete_contact_unknown_error'),
  };

  return errors[err] || t('api_error_domain_delete_contact_unknown_error');
}

export function getUserUpdateEmailError(err: ca2users.UpdateEmailError): string {
  const errors = {
    [ca2users.UpdateEmailError.UEE_EMAIL_BELONGS_TO_ANOTHER_USER_ERROR]: t(
      'api_error_user_email_belongs_to_another_user',
    ),
    [ca2users.UpdateEmailError.UEE_INVALID_EMAIL_ERROR]: t('api_error_user_email_invalid_user'),
    [ca2users.UpdateEmailError.UEE_UNKNOWN_ERROR]: t('api_error_user_unknown_error'),
  };

  return errors[err] || t('api_error_user_unknown_error');
}

export function getUserUpdateEmailConfirmationError(err: ca2users.UpdateEmailConfirmationError): string {
  const errors = {
    [ca2users.UpdateEmailConfirmationError.UECE_CODE_EXPIRED_ERROR]: t('api_error_user_code_expired_error'),
    [ca2users.UpdateEmailConfirmationError.UECE_INVALID_CODE_ERROR]: t('api_error_user_invalid_code_error'),
    [ca2users.UpdateEmailConfirmationError.UECE_TOO_MANY_ATTEMPTS_ERROR]: t(
      'api_error_user_code_to_many_attempts_error',
    ),
    [ca2users.UpdateEmailConfirmationError.UECE_UNKNOWN_ERROR]: t('api_error_user_unknown_error'),
  };

  return errors[err] || t('api_error_user_unknown_error');
}

export function getUserUpdatePasswordError(err: ca2users.UpdatePasswordError): string {
  const errors = {
    [ca2users.UpdatePasswordError.UPE_INVALID_OLD_PASSWORD_ERROR]: t(
      'api_error_user_update_password_invalid_old_error',
    ),
    [ca2users.UpdatePasswordError.UPE_NEW_PASSWORD_IS_TOO_WEAK_ERROR]: t(
      'api_error_user_update_password_is_too_weak_error',
    ),
    [ca2users.UpdatePasswordError.UPE_PASSWORDS_DO_NOT_MATCH_ERROR]: t(
      'api_error_user_update_password_not_match_error',
    ),
    [ca2users.UpdatePasswordError.UPE_UNKNOWN_ERROR]: t('api_error_user_update_password_unknown_error'),
  };

  return errors[err] || t('api_error_user_unknown_error');
}

ca2domains.ChangeDomainNameServersError;
