import {action, computed, makeObservable} from 'mobx';
import {ca2instances, ca2types} from '../../api/proto';
import {formatDuration} from '../../utils/format/format';
import {Plan} from '../Plan';
import BaseInstance from './BaseInstance';
import InstancesStore from './InstancesStore';
import InstanceStore from './instanceStore';

export enum InstanceEvent {
  DELETING = 'DELETING',
  DELETED = 'DELETED',
  RESTARTING = 'RESTARTING',
}

export class Instance extends BaseInstance {
  constructor(public raw: ca2types.IInstance, public instancesStore: InstancesStore) {
    super(raw);
    makeObservable(this);
  }

  store: InstanceStore = new InstanceStore(this);

  @computed get uptimeFormatted(): string {
    if (this.uptime) {
      return formatDuration(this.uptime.toNumber());
    }

    return '';
  }

  @computed get provider() {
    if (!this.providerId) {
      return null;
    }

    return this.instancesStore.app.serversStore.providers.findById(this.providerId) || null;
  }

  @computed get datacenter(): ca2types.IDatacenter | null {
    return this.instancesStore.app.serversStore.datacenters.list.find(({id}) => id && this.datacenterId === id) || null;
  }

  @computed get image(): ca2types.IImage | null {
    if (!this.imageId) {
      return null;
    }

    return this.instancesStore.app.serversStore.images.findImageById(this.imageId);
  }

  @computed get imageName(): string | null {
    return this.image?.name || null;
  }

  @computed get datacenterCity(): string | null {
    return this.datacenter?.city || null;
  }

  @computed get plan(): Plan | null {
    return this.instancesStore.app.plansStore.findById(this.planId);
  }

  @action update = (raw: ca2types.IInstance) => {
    this.onUpdateStatus(raw.instanceStatus);

    this.raw = {...this.raw, ...raw};
    this.update_(raw);
  };

  delete = () => {
    return this.store.deleteInstance();
  };

  start = () => {
    this.store.startInstance();
  };

  stop = () => {
    this.store.stopInstance();
  };

  restart = () => {
    this.store.restartInstance();
  };

  updateRequest = async (data: ca2instances.IUpdateInstanceRequest) => {
    return await this.store.updateInstance(data);
  };

  toggleAddToFavorites = async () => {
    const isInFavorites = !this.isImportant;

    this.update({
      isImportant: isInFavorites,
    });

    const {res, error} = await this.updateRequest({isImportant: isInFavorites});

    if (res?.errors?.length || error) {
      this.update({
        isImportant: !isInFavorites,
      });
    }
  };

  onUpdateStatus = (status?: ca2types.InstanceStatus | null) => {
    if (this.raw.instanceStatus === status) {
      return;
    }

    switch (status) {
      case ca2types.InstanceStatus.IS_DELETING:
        this.emit(InstanceEvent.DELETING, this);
        break;
      case ca2types.InstanceStatus.IS_DELETED:
        this.emit(InstanceEvent.DELETED, this);
        break;
      case ca2types.InstanceStatus.IS_RESTARTING:
        this.emit(InstanceEvent.RESTARTING, this);
        break;
    }
  };
}

export default Instance;
