import {action, computed, makeObservable, observable} from 'mobx';
import {ca2domains, ca2types} from '../../api/proto';
import Domain from './Domain';

export const EMPTY_DNS_RECORD: IDNSRecord = {
  aRecord: {
    hostname: '',
    ip: '',
  },
  aaaaRecord: {
    hostname: '',
    ip: '',
  },
  cnameRecord: {
    hostname: '',
    alias: '',
  },
  mxRecord: {
    mailServer: '',
    priority: 0,
  },
  txtRecord: {
    hostname: '',
    text: '',
  },
  srvRecord: {
    priority: 0,
    weight: 0,
    service: '',
    port: 0,
    protocol: '',
    target: '',
  },
  caaRecord: {
    hostname: '',
    flag: 0,
  },
};

export type INSRecord = {
  nameServer: string;
};

export type DNSRecordKeyType = keyof Omit<
  ca2types.IDNSRecord & {
    nsRecord?: INSRecord | null;
    unknownRecord?: null;
  },
  'id' | 'type' | 'ttl'
>;

export interface IDNSRecord extends Omit<ca2types.IDNSRecord, 'type'> {
  nsRecord?: INSRecord | null;
  type?: ca2types.DNSRecordType | 'NS';
}

export type RecordTypeWithTTL<T> = T & {ttl?: number | null};

export type RecordType =
  | RecordTypeWithTTL<ca2types.IARecord>
  | RecordTypeWithTTL<ca2types.IAAAARecord>
  | RecordTypeWithTTL<ca2types.ICNAMERecord>
  | RecordTypeWithTTL<ca2types.IMXRecord>
  | RecordTypeWithTTL<ca2types.ITXTRecord>
  | RecordTypeWithTTL<ca2types.ISRVRecord>
  | RecordTypeWithTTL<ca2types.ICAARecord>
  | RecordTypeWithTTL<INSRecord>;

const recordTypeKeyMap: Record<ca2types.DNSRecordType, DNSRecordKeyType> = {
  [ca2types.DNSRecordType.DRT_A]: 'aRecord',
  [ca2types.DNSRecordType.DRT_AAAA]: 'aaaaRecord',
  [ca2types.DNSRecordType.DRT_CNAME]: 'cnameRecord',
  [ca2types.DNSRecordType.DRT_MX]: 'mxRecord',
  [ca2types.DNSRecordType.DRT_TXT]: 'txtRecord',
  [ca2types.DNSRecordType.DRT_SRV]: 'srvRecord',
  [ca2types.DNSRecordType.DRT_CAA]: 'caaRecord',
  [ca2types.DNSRecordType.DRT_UNKNOWN]: 'unknownRecord',
};

export function updateDnsRecord(dnsRecord: IDNSRecord, record: RecordType): IDNSRecord {
  const updatedRecord: IDNSRecord = {...dnsRecord};

  switch (dnsRecord.type) {
    case ca2types.DNSRecordType.DRT_A:
      updatedRecord.aRecord = record as ca2types.IARecord;
      break;
    case ca2types.DNSRecordType.DRT_AAAA:
      updatedRecord.aaaaRecord = record as ca2types.IAAAARecord;
      break;
    case ca2types.DNSRecordType.DRT_CNAME:
      updatedRecord.cnameRecord = record as ca2types.ICNAMERecord;
      break;
    case ca2types.DNSRecordType.DRT_MX:
      updatedRecord.mxRecord = record as ca2types.IMXRecord;
      break;
    case ca2types.DNSRecordType.DRT_TXT:
      updatedRecord.txtRecord = record as ca2types.ITXTRecord;
      break;
    case ca2types.DNSRecordType.DRT_SRV:
      updatedRecord.srvRecord = record as ca2types.ISRVRecord;
      break;
    case ca2types.DNSRecordType.DRT_CAA:
      updatedRecord.caaRecord = record as ca2types.ICAARecord;
      break;
    case 'NS':
      updatedRecord.nsRecord = record as INSRecord;
      break;
  }

  return updatedRecord;
}

export class DnsRecordsStore {
  @observable private records_: IDNSRecord[] = [];
  @observable isInit: boolean = false;

  constructor(public domain: Domain) {
    makeObservable(this);
  }

  private getRecordsWithTTL<T extends object>(
    type: ca2types.DNSRecordType,
    key: keyof ca2types.IDNSRecord,
  ): RecordTypeWithTTL<T>[] {
    return this.records_
      .filter((record) => record.type === type && record[key])
      .map((record) => {
        return {
          ...record,
          [key]: {
            ...(record[key] as object),
            ttl: record.ttl,
          },
        } as RecordTypeWithTTL<T>;
      });
  }

  @computed get aRecords(): RecordTypeWithTTL<ca2types.IARecord>[] {
    return this.getRecordsWithTTL<ca2types.IARecord>(ca2types.DNSRecordType.DRT_A, 'aRecord');
  }

  @computed get aaaaRecords(): RecordTypeWithTTL<ca2types.IAAAARecord>[] {
    return this.getRecordsWithTTL<ca2types.IAAAARecord>(ca2types.DNSRecordType.DRT_AAAA, 'aaaaRecord');
  }

  @computed get cnameRecords(): RecordTypeWithTTL<ca2types.ICNAMERecord>[] {
    return this.getRecordsWithTTL<ca2types.ICNAMERecord>(ca2types.DNSRecordType.DRT_CNAME, 'cnameRecord');
  }

  @computed get mxRecords(): RecordTypeWithTTL<ca2types.IMXRecord>[] {
    return this.getRecordsWithTTL<ca2types.IMXRecord>(ca2types.DNSRecordType.DRT_MX, 'mxRecord');
  }

  @computed get txtRecords(): RecordTypeWithTTL<ca2types.ITXTRecord>[] {
    return this.getRecordsWithTTL<ca2types.ITXTRecord>(ca2types.DNSRecordType.DRT_TXT, 'txtRecord');
  }

  @computed get srvRecords(): RecordTypeWithTTL<ca2types.ISRVRecord>[] {
    return this.getRecordsWithTTL<ca2types.ISRVRecord>(ca2types.DNSRecordType.DRT_SRV, 'srvRecord');
  }

  @computed get caaRecords(): RecordTypeWithTTL<ca2types.ICAARecord>[] {
    return this.getRecordsWithTTL<ca2types.ICAARecord>(ca2types.DNSRecordType.DRT_CAA, 'caaRecord');
  }

  init = () => {
    if (this.isInit) {
      return;
    }

    this.loadDnsRecords();
    this.isInit = true;
  };

  loadDnsRecords = async () => {
    const {res, error} = await this.domain.domainsStore.request({
      domains: {
        dnsRecordList: {
          domainId: this.domain.id,
        },
      },
    });

    if (res?.domains?.dnsRecordList) {
      this.loadDnsRecordsProcess_(res.domains.dnsRecordList);
    }

    return {error, res: res?.domains?.dnsRecordList};
  };

  @action private loadDnsRecordsProcess_ = (res: ca2domains.IDNSRecordListResponse) => {
    if (res.records) {
      this.records_ = res.records as IDNSRecord[];
    }
  };

  createOrUpdateDnsRecord = async (record: IDNSRecord) => {
    const recordByType = record.type ? record[recordTypeKeyMap[record.type]] : null;

    if (recordByType) {
      record.ttl = typeof +recordByType.ttl === 'number' ? +recordByType.ttl : 0;
      delete recordByType.ttl;
    }

    const {res, error} = await this.domain.domainsStore.request({
      domains: {
        dnsRecordCreateOrUpdate: {
          domainId: this.domain.id,
          record: record as ca2types.IDNSRecord,
        },
      },
    });

    if (res?.domains?.dnsRecordCreateOrUpdate) {
      this.createOrUpdateDnsRecordProcess_(res.domains.dnsRecordCreateOrUpdate);
    }

    return {error, res: res?.domains?.dnsRecordCreateOrUpdate};
  };

  private createOrUpdateDnsRecordProcess_ = (res: ca2domains.IDNSRecordCreateOrUpdateResponse) => {
    if (res.record) {
      const record = res.record as IDNSRecord;
      const index = this.records_.findIndex((r) => record.id && r.id?.equals(record.id));

      if (index !== -1) {
        this.records_[index] = record;
      } else {
        this.records_.push(record);
      }

      this.records_.slice();
    }
  };

  deleteDnsRecord = async (record: IDNSRecord) => {
    const {res, error} = await this.domain.domainsStore.request({
      domains: {
        dnsRecordDelete: {
          domainId: this.domain.id,
          recordId: record.id,
        },
      },
    });

    if (res?.domains?.dnsRecordDelete) {
      this.deleteDnsRecordProcess_(res.domains.dnsRecordDelete, record);
    }

    return {error, res: res?.domains?.dnsRecordDelete};
  };

  @action private deleteDnsRecordProcess_ = (res: ca2domains.IDNSRecordDeleteResponse, record: IDNSRecord) => {
    if (res.errors?.length) {
      return;
    }

    const index = this.records_.findIndex((r) => r.id === record.id);
    if (index !== -1) {
      this.records_.splice(index, 1);
    }
  };
}

export default DnsRecordsStore;
