import {Box, styled, Typography, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {getApiDNSRecordCreateOrUpdateError, getApiDNSRecordDeleteError} from '../../../api/getApiError';
import {ca2types} from '../../../api/proto';
import {ReactComponent as InfoIcon} from '../../../assets/icons/info-blue.svg';
import PageContentLayout from '../../../layouts/PageContentLayout';
import {useStore} from '../../../stores/AppStore';
import {Domain} from '../../../stores/Domain';
import {DNSRecordKeyType, EMPTY_DNS_RECORD, IDNSRecord, RecordType} from '../../../stores/Domain/DnsRecordsStore';
import DomainRecordsTable, {DomainRecordColumn} from '../DomainRecordsTable';
import {IDomainRecordsTableRef} from '../DomainRecordsTable/DomainRecordsTable';

const TableBox = styled(Box)(({theme}) => ({
  padding: theme.spacing(4),
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.border.primary}`,
  },
}));

interface IProps {
  domain: Domain;
}

interface RenderTableProps {
  type: ca2types.DNSRecordType;
  recordKey: DNSRecordKeyType;
  columns: DomainRecordColumn[];
  dnsRecords: IDNSRecord[];
  title: string;
  addButtonText: string;
  ref: React.RefObject<IDomainRecordsTableRef> | null;
}

interface RecordTableDataType {
  dnsRecords: IDNSRecord[];
  title: string;
  addButtonText: string;
  type: ca2types.DNSRecordType;
  recordKey: DNSRecordKeyType;
}

const generateColumns = (record: RecordType, t: (key: string) => string): DomainRecordColumn[] => {
  return [
    ...Object.keys(record)
      .filter((objKey) => !['id', 'type'].includes(objKey))
      .map((objKey) => ({
        name: objKey,
        label: t(`domain_records_table_${objKey}_column`),
      })),
    {name: 'ttl', label: t('domain_records_table_ttl_column')},
  ];
};

export const DomainDnsRecords: React.FC<IProps> = observer(({domain}) => {
  const theme = useTheme();
  const {notification} = useStore();
  const {t} = useTranslation();
  const {dnsRecords} = domain;

  const aRecordsRef = useRef<IDomainRecordsTableRef>(null);
  const aaaaRecordsRef = useRef<IDomainRecordsTableRef>(null);
  const cnameRecordsRef = useRef<IDomainRecordsTableRef>(null);
  const mxRecordsRef = useRef<IDomainRecordsTableRef>(null);
  const txtRecordsRef = useRef<IDomainRecordsTableRef>(null);
  const srvRecordsRef = useRef<IDomainRecordsTableRef>(null);
  const caaRecordsRef = useRef<IDomainRecordsTableRef>(null);

  const DNSRecordTypeNames: Record<ca2types.DNSRecordType, string> = {
    [ca2types.DNSRecordType.DRT_A]: 'A',
    [ca2types.DNSRecordType.DRT_AAAA]: 'AAAA',
    [ca2types.DNSRecordType.DRT_CNAME]: 'CNAME',
    [ca2types.DNSRecordType.DRT_MX]: 'MX',
    [ca2types.DNSRecordType.DRT_TXT]: 'TXT',
    [ca2types.DNSRecordType.DRT_SRV]: 'RSV',
    [ca2types.DNSRecordType.DRT_CAA]: ' CAA',
    [ca2types.DNSRecordType.DRT_UNKNOWN]: '',
  };

  const tableRecordsRefMap: Record<ca2types.DNSRecordType, React.RefObject<IDomainRecordsTableRef>> = {
    [ca2types.DNSRecordType.DRT_A]: aRecordsRef,
    [ca2types.DNSRecordType.DRT_AAAA]: aaaaRecordsRef,
    [ca2types.DNSRecordType.DRT_CNAME]: cnameRecordsRef,
    [ca2types.DNSRecordType.DRT_MX]: mxRecordsRef,
    [ca2types.DNSRecordType.DRT_TXT]: txtRecordsRef,
    [ca2types.DNSRecordType.DRT_SRV]: srvRecordsRef,
    [ca2types.DNSRecordType.DRT_CAA]: caaRecordsRef,
    [ca2types.DNSRecordType.DRT_UNKNOWN]: {current: null},
  };

  const recordTablesData: RecordTableDataType[] = [
    {
      dnsRecords: dnsRecords.aRecords,
      title: t('domain_dns_records_a_records_title'),
      addButtonText: t('domain_dns_records_add_a_record_button'),
      type: ca2types.DNSRecordType.DRT_A,
      recordKey: 'aRecord',
    },
    {
      dnsRecords: dnsRecords.aaaaRecords,
      title: t('domain_dns_records_aaaa_records_title'),
      addButtonText: t('domain_dns_records_add_aaaa_record_button'),
      type: ca2types.DNSRecordType.DRT_AAAA,
      recordKey: 'aaaaRecord',
    },
    {
      dnsRecords: dnsRecords.cnameRecords,
      title: t('domain_dns_records_cname_records_title'),
      addButtonText: t('domain_dns_records_add_cname_record_button'),
      type: ca2types.DNSRecordType.DRT_CNAME,
      recordKey: 'cnameRecord',
    },
    {
      dnsRecords: dnsRecords.mxRecords,
      title: t('domain_dns_records_mx_records_title'),
      addButtonText: t('domain_dns_records_add_mx_record_button'),
      type: ca2types.DNSRecordType.DRT_MX,
      recordKey: 'mxRecord',
    },
    {
      dnsRecords: dnsRecords.txtRecords,
      title: t('domain_dns_records_txt_records_title'),
      addButtonText: t('domain_dns_records_add_txt_record_button'),
      type: ca2types.DNSRecordType.DRT_TXT,
      recordKey: 'txtRecord',
    },
    {
      dnsRecords: dnsRecords.srvRecords,
      title: t('domain_dns_records_srv_records_title'),
      addButtonText: t('domain_dns_records_add_srv_record_button'),
      type: ca2types.DNSRecordType.DRT_SRV,
      recordKey: 'srvRecord',
    },
    {
      dnsRecords: dnsRecords.caaRecords,
      title: t('domain_dns_records_caa_records_title'),
      addButtonText: t('domain_dns_records_add_caa_record_button'),
      type: ca2types.DNSRecordType.DRT_CAA,
      recordKey: 'caaRecord',
    },
  ];

  const handleAddOrUpdateRecord = async (dnsRecord: IDNSRecord) => {
    const {res, error} = await domain.dnsRecords.createOrUpdateDnsRecord(dnsRecord);

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors} = res;

      const recordRef = dnsRecord.type ? tableRecordsRefMap[dnsRecord.type] : null;

      if (errors?.length) {
        for (const error of errors) {
          const errorMessage = getApiDNSRecordCreateOrUpdateError(error);

          //TODO need to fix set errors for every record by type

          // if (recordRef) {
          //   recordRef.current?.setFormErrors(dnsRecord.id, errorMessage);
          // }

          notification.error(errorMessage);
        }

        return;
      }

      if (dnsRecord.id) {
        recordRef.current?.resetForm(dnsRecord.id.toString());
      }

      notification.success(
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            {t(
              dnsRecord.id ? 'domain_dns_records_update_success_message' : 'domain_dns_records_create_success_message',
              {
                type: dnsRecord.type ? DNSRecordTypeNames[dnsRecord.type] : '',
              },
            )}
          </Typography>
          <Typography variant="body2" color="body.secondary">
            {t('domain_dns_records_update_success_message_text')}
          </Typography>
        </Box>,
      );

      recordRef.current?.hideRecordForm();
    }
  };

  const handleDeleteRecord = async (dnsRecord: IDNSRecord) => {
    const {res, error} = await domain.dnsRecords.deleteDnsRecord(dnsRecord);

    if (error) {
      notification.error(error.message);
      return;
    }

    if (res?.errors?.length) {
      for (const error of res.errors) {
        const errorMessage = getApiDNSRecordDeleteError(error);

        const recordRef = dnsRecord.type ? tableRecordsRefMap[dnsRecord.type] : null;

        if (recordRef) {
          recordRef.current?.setFormErrors(dnsRecord.id, errorMessage);
        }
      }

      return;
    }

    notification.success(t('domain_dns_records_delete_success_message'));
  };

  const isReadonlyMode = !domain.isDefaultNameServers;

  const renderTable = ({type, title, ref, columns, dnsRecords, addButtonText, recordKey}: RenderTableProps) => (
    <TableBox>
      <Typography variant="label2" sx={{display: 'block'}} gutterBottom>
        {title}
      </Typography>
      <DomainRecordsTable
        ref={ref}
        recordType={type}
        recordKey={recordKey}
        columns={columns}
        dnsRecords={dnsRecords}
        addRecordButtonText={addButtonText}
        onUpdateRecord={handleAddOrUpdateRecord}
        onAddNewRecord={handleAddOrUpdateRecord}
        onDeleteRecord={handleDeleteRecord}
        readOnly={isReadonlyMode}
      />
    </TableBox>
  );

  return (
    <PageContentLayout sx={{padding: 0}}>
      {isReadonlyMode ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingX: theme.spacing(4),
            paddingY: theme.spacing(3),
            borderBottom: `1px solid ${theme.palette.border.primary}`,
          }}
        >
          <InfoIcon />
          <Typography variant="body1" sx={{marginLeft: theme.spacing(2)}}>
            {t('domain_dns_records_use_providers_record_message')}
          </Typography>
        </Box>
      ) : null}

      {recordTablesData
        .filter(({dnsRecords}) => (isReadonlyMode ? dnsRecords.length : true))
        .map(({dnsRecords, title, addButtonText, type, recordKey}) => (
          <React.Fragment key={type}>
            {renderTable({
              type,
              ref: tableRecordsRefMap[type],
              columns: generateColumns(EMPTY_DNS_RECORD[recordKey] as RecordType, t),
              dnsRecords,
              title,
              addButtonText,
              recordKey,
            })}
          </React.Fragment>
        ))}
    </PageContentLayout>
  );
});

export default DomainDnsRecords;
