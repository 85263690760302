import {Box, Button, SxProps, Tooltip, styled, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CopyIcon} from '../../assets/icons/copy.svg';
import {ReactComponent as CloseEyeIcon} from '../../assets/icons/eye-close.svg';
import {ReactComponent as OpenEyeIcon} from '../../assets/icons/eye-open.svg';

const StyledBox = styled(Box)(({theme}) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .actions': {
    display: 'inline-flex',
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  '& .actions button': {
    minWidth: 0,
    backgroundColor: theme.palette.backgroundPrimary,
  },
  '& .value': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export interface ICopyableBoxRef {
  hideButtons(): void;
  showButtons(): void;
  toggleMaskedValue(): void;
  copyToClipboard(): void;
}

interface IProps {
  className?: string;
  value?: string | null;
  maskedValue?: boolean;
  showCopyButton?: boolean;
  sx?: SxProps;
}

const CopyableBox = React.forwardRef<ICopyableBoxRef, IProps>((props, ref) => {
  const {t} = useTranslation();
  const {palette} = useTheme();

  const showToggleMaskedValueButton = !!props.maskedValue;

  const [openTooltip, setOpenTooltip] = React.useState<boolean>(false);
  const [showButtons, setShowButtons] = React.useState<boolean>(!!props.showCopyButton);
  const [isMaskedValue, setMaskedValue] = React.useState<boolean>(!!props.maskedValue);

  const copyToClipboard = async () => {
    if (typeof props.value !== 'string') {
      return;
    }

    try {
      await navigator.clipboard.writeText(props.value);
      setOpenTooltip(true);
      setTimeout(() => {
        setOpenTooltip(false);
      }, 1000);
    } catch (error) {
      console.error('Error on copy to clipboard: ', error);
    }
  };

  const handleCopy = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    copyToClipboard();
  };

  React.useImperativeHandle(ref, () => ({
    hideButtons: () => {
      setShowButtons(false);
    },
    showButtons: () => {
      setShowButtons(true);
    },
    toggleMaskedValue: () => {
      setMaskedValue(!isMaskedValue);
    },
    copyToClipboard: copyToClipboard,
  }));

  const handleToggleMaskedValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMaskedValue(!isMaskedValue);
  };

  if (!props.value) {
    return null;
  }

  return (
    <StyledBox className={props.className} sx={props.sx}>
      <Box className="value">{isMaskedValue ? '•'.repeat(props.value.length) : props.value}</Box>

      <Box className="actions">
        {showButtons ? (
          <Tooltip title={t('component_copyable_text_input_copy_to_clipboard')} open={openTooltip}>
            <Button variant="outlined" size="small" onClick={handleCopy}>
              <CopyIcon style={{fill: palette.body.primary, cursor: 'pointer'}} />
            </Button>
          </Tooltip>
        ) : null}

        {showButtons && showToggleMaskedValueButton ? (
          <Button variant="outlined" size="small" onClick={handleToggleMaskedValue}>
            {isMaskedValue ? (
              <CloseEyeIcon width="24" height="24" style={{fill: palette.body.primary, cursor: 'pointer'}} />
            ) : (
              <OpenEyeIcon width="24" height="24" style={{fill: palette.body.primary, cursor: 'pointer'}} />
            )}
          </Button>
        ) : null}
      </Box>
    </StyledBox>
  );
});

export default CopyableBox;
