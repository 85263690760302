import {Grid} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ProviderLogo from '../../../components/ProviderLogo';
import RegionLabel from '../../../components/RegionLabel';
import CopyableBox from '../../../components/UI/CopyableBox';
import Typography from '../../../components/UI/Typography';
import {Instance} from '../../../stores/Instance';
import OverviewItem from './OverviewItem';

interface IProps {
  instance: Instance;
}

const GeneralInfo: React.FC<IProps> = observer(({instance}) => {
  const {t} = useTranslation();

  const getLastRowColSpan = () => {
    const values = [instance.ipv4, instance.ipv6, instance.rootPassword].filter(Boolean).length;
    return values === 3 ? 4 : values === 2 ? 6 : 12;
  };

  const lastRowColSpan = getLastRowColSpan();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={4}>
        <OverviewItem
          label={t('server_info_overview_total_charge')}
          value={instance.totalChargeFormatter.formatted}
          ValueTypographyProps={{variant: 'h4'}}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <OverviewItem
          label={t('server_info_overview_price_per_month')}
          value={instance.plan?.monthlyRateFormatter.formatted}
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <OverviewItem
          label={t('server_info_overview_price_per_hour')}
          value={instance.plan?.hourlyRateFormatter.formatted}
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <OverviewItem label={t('server_info_overview_uptime')} value={instance.uptimeFormatted} />
      </Grid>

      {instance.datacenter ? (
        <Grid item xs={12} lg={4}>
          <OverviewItem
            label={t('server_info_overview_datacenter')}
            value={
              <Typography variant="body2">
                <RegionLabel regionOrDatacenter={instance.datacenter} />
              </Typography>
            }
          />
        </Grid>
      ) : null}

      {instance.plan?.providerId ? (
        <Grid item xs={12} lg={4}>
          <OverviewItem
            label={t('server_info_overview_provider')}
            value={
              <>
                <ProviderLogo providerId={instance.plan.providerId} />
                <Typography variant="body2">{instance.plan.provider?.title}</Typography>
              </>
            }
          />
        </Grid>
      ) : null}

      {instance.ipv4 ? (
        <Grid item xs={12} lg={lastRowColSpan}>
          <OverviewItem
            label={t('server_info_overview_ipv4')}
            value={<CopyableBox showCopyButton value={instance.ipv4} />}
          />
        </Grid>
      ) : null}

      {instance.ipv6 ? (
        <Grid item xs={12} lg={lastRowColSpan}>
          <OverviewItem
            label={t('server_info_overview_ipv6')}
            value={<CopyableBox showCopyButton value={instance.ipv6} />}
          />
        </Grid>
      ) : null}

      {instance.rootPassword ? (
        <Grid item xs={12} lg={lastRowColSpan}>
          <OverviewItem
            label={t('server_info_overview_root_password')}
            value={<CopyableBox value={instance.rootPassword} showCopyButton maskedValue />}
          />
        </Grid>
      ) : null}
    </Grid>
  );
});

export default GeneralInfo;
