import {ListItemIcon, ListItemText, MenuList} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as BillingIcon} from '../../assets/icons/billing.svg';
// import {ReactComponent as NotificationsIcon} from '../../assets/icons/notifications.svg';
import {generatePath} from 'react-router';
import SidebarMenuItem from '../../components/SidebarMenuItem';
import Paths from '../../routes/Paths';
import {BillingPageTab} from '../BillingPage/BillingPage';
import {MenuItemDataType} from './types';

export const FooterMenu: React.FC = () => {
  const {t} = useTranslation();

  const items: MenuItemDataType[] = [
    {
      path: generatePath(Paths.Billing, {
        tabType: BillingPageTab.BILLING_DETAILS,
      }) as Paths,
      title: t('sidebar_footer_menu_item_billing'),
      icon: <BillingIcon />,
    },
    // {
    //   path: Paths.Notifications,
    //   title: t('sidebar_footer_menu_item_notifications'),
    //   icon: <NotificationsIcon />,
    // },
  ];

  return (
    <MenuList sx={{marginTop: 'auto'}}>
      {items.map(({path, title, icon}) => (
        <SidebarMenuItem key={path} path={path}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{title}</ListItemText>
        </SidebarMenuItem>
      ))}
    </MenuList>
  );
};

export default FooterMenu;
