import {LoadingButton} from '@mui/lab';
import {Box, Button, TableRow, useTheme} from '@mui/material';
import {FormikErrors, useFormik} from 'formik';
import React, {forwardRef, useImperativeHandle} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import TextField from '../../../components/UI/TextField';
import {RecordType} from '../../../stores/Domain/DnsRecordsStore';
import {DomainRecordTableCell} from './DomainRecordsTable';
import {DomainRecordColumn} from './types';

export interface IDomainRecordTableFormRef {
  reset(): void;
  setErrors(errors: FormikErrors<RecordType>): void;
}

interface IProps {
  record?: RecordType;
  columns: DomainRecordColumn[];
  onCancel?(): void;
  onSubmit?(newRecord: RecordType): Promise<void>;
}

export const DomainRecordTableForm = forwardRef(function DomainRecordTableForm(
  {record, columns, ...props}: IProps,
  ref: React.Ref<IDomainRecordTableFormRef>,
) {
  const theme = useTheme();
  const {t} = useTranslation();

  const [loading, setLoading] = React.useState(false);

  const getSchema = (columns: DomainRecordColumn[]) => {
    const fields = columns.reduce((acc, column) => {
      if (column.name === 'ttl') {
        acc[column.name as string] = Yup.number()
          .typeError(t('domain_records_table_ttl_validation'))
          .required(t('domain_records_table_field_required', {label: column.label}));
      } else {
        acc[column.name as string] = Yup.string().required(
          t('domain_records_table_field_required', {label: column.label}),
        );
      }

      return acc;
    }, {});

    return Yup.object().shape(fields);
  };

  const initialValues = React.useMemo(() => {
    return columns.reduce(
      (acc, column) => {
        acc[column.name as string] = '';
        return acc;
      },
      {ttl: 3600},
    );
  }, [columns]);

  const formik = useFormik<RecordType>({
    initialValues: record || (initialValues as RecordType),
    validationSchema: getSchema(columns),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true);
      await props.onSubmit?.(values);
      setLoading(false);
    },
  });

  // const handleSubmit = async () => {};

  const handleCancel = () => {
    formik.resetForm();
    props.onCancel?.();
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      formik.resetForm();
    },
    setErrors: (errors: FormikErrors<RecordType>) => {
      formik.setErrors(errors);
    },
  }));

  return (
    <TableRow>
      {columns.map((column, idx) => {
        const isMultiColumns = columns.length > 1;
        const isFirstColumn = idx === 0;
        const isLastColumn = columns.length - 1 === idx;

        if (isLastColumn) {
          return (
            <DomainRecordTableCell key={String(column.name)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                }}
              >
                <TextField
                  name={column.name as string}
                  value={formik.values[column.name]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.errors[column.name]}
                  helperText={formik.errors[column.name] as string}
                  placeholder={t('domain_records_table_field_placeholder', {label: column.label.toLowerCase()})}
                  fullWidth
                  bottomGutter={false}
                  autoFocus={!isMultiColumns}
                />
                <Box sx={{marginLeft: theme.spacing(2)}}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    size="large"
                    sx={{marginRight: theme.spacing(2)}}
                    onClick={formik.submitForm}
                  >
                    {t('action_button_save')}
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{minWidth: 0, paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2)}}
                    onClick={handleCancel}
                  >
                    {t('action_button_cancel')}
                  </Button>
                </Box>
              </Box>
            </DomainRecordTableCell>
          );
        }

        return (
          <DomainRecordTableCell sx={{verticalAlign: 'top'}} key={String(column.name)}>
            <TextField
              name={column.name as string}
              value={formik.values[column.name]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.errors[column.name]}
              helperText={formik.errors[column.name] as string}
              placeholder={t('domain_records_table_field_placeholder', {label: column.label.toLowerCase()})}
              fullWidth
              bottomGutter={false}
              autoFocus={isMultiColumns && isFirstColumn}
            />
          </DomainRecordTableCell>
        );
      })}
    </TableRow>
  );
});

export default DomainRecordTableForm;
