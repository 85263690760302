import React from 'react';
import {t} from '../../i18n';
import browserStorage from '../../utils/browserStorage';

export enum ColumnType {
  Hostname = 'hostname',
  Image = 'image',
  Datacenter = 'datecenter',
  Status = 'status',
  IPv4 = 'ipv4',
  IPv6 = 'ipv6',
  RootPassword = 'rootPassword',
  Host = 'host',
}

type ColumnAlign = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export type Column = {
  id: ColumnType;
  visible: boolean;
  label: string;
  minWidth: number;
  width?: number;
  align: ColumnAlign;
  allowHide?: boolean;
};

type ColumnsMap = Record<ColumnType, boolean>;

export type ServerTableColumns = {
  columns: Column[];
  toggleColumn(columnId: ColumnType): void;
  resetColumns(): void;
};

export const ACTIONS_COLUMN_WIDTH = 60; //px;
const COLUMN_MIN_WIDTH = 150; //px;
const COLUMN_ALIGN: ColumnAlign = 'left';

const defaultColumnsMap: ColumnsMap = {
  [ColumnType.Hostname]: true,
  [ColumnType.Image]: true,
  [ColumnType.Datacenter]: true,
  [ColumnType.Host]: true,
  [ColumnType.Status]: true,
  [ColumnType.IPv4]: true,
  [ColumnType.RootPassword]: true,
  [ColumnType.IPv6]: false,
};

const defaultColumns: Column[] = [
  {
    id: ColumnType.Hostname,
    visible: defaultColumnsMap[ColumnType.Hostname],
    label: t('servers_table_hostname_column'),
    minWidth: COLUMN_MIN_WIDTH,
    align: COLUMN_ALIGN,
  },
  {
    id: ColumnType.Host,
    visible: defaultColumnsMap[ColumnType.Host],
    label: t('servers_table_host_column'),
    minWidth: COLUMN_MIN_WIDTH,
    align: COLUMN_ALIGN,
    allowHide: true,
  },
  {
    id: ColumnType.Image,
    visible: defaultColumnsMap[ColumnType.Image],
    label: t('servers_table_image_column'),
    minWidth: COLUMN_MIN_WIDTH,
    align: COLUMN_ALIGN,
    allowHide: true,
  },
  {
    id: ColumnType.Datacenter,
    visible: defaultColumnsMap[ColumnType.Datacenter],
    label: t('servers_table_datacenter_column'),
    minWidth: COLUMN_MIN_WIDTH,
    align: COLUMN_ALIGN,
    allowHide: true,
  },
  {
    id: ColumnType.Status,
    visible: defaultColumnsMap[ColumnType.Status],
    label: t('servers_table_status_column'),
    minWidth: COLUMN_MIN_WIDTH,
    align: COLUMN_ALIGN,
  },
  {
    id: ColumnType.IPv4,
    visible: defaultColumnsMap[ColumnType.IPv4],
    label: t('servers_table_ipv4_column'),
    minWidth: COLUMN_MIN_WIDTH,
    align: COLUMN_ALIGN,
  },
  {
    id: ColumnType.IPv6,
    visible: defaultColumnsMap[ColumnType.IPv6],
    label: t('servers_table_ipv6_column'),
    minWidth: COLUMN_MIN_WIDTH,
    align: COLUMN_ALIGN,
    allowHide: true,
  },
  {
    id: ColumnType.RootPassword,
    visible: defaultColumnsMap[ColumnType.RootPassword],
    label: t('servers_table_root_password_column'),
    minWidth: COLUMN_MIN_WIDTH,
    align: COLUMN_ALIGN,
    allowHide: true,
  },
];

const columnsStoreKey = '_serv_tb_clms';

export const useServersTableColumns = (): ServerTableColumns => {
  const [columnsMap, setColumnsMap] = React.useState<ColumnsMap>(() => {
    const storedColumnsMap = browserStorage.getItem(columnsStoreKey);

    if (storedColumnsMap) {
      return JSON.parse(storedColumnsMap);
    }

    return defaultColumnsMap;
  });

  const resetColumns = () => {
    setColumnsMap(defaultColumnsMap);
    browserStorage.setItem(columnsStoreKey, JSON.stringify(defaultColumnsMap));
  };

  const toggleColumn = React.useCallback(
    (columnId: ColumnType) => {
      const toUpdate = {...columnsMap};
      toUpdate[columnId] = !toUpdate[columnId];
      setColumnsMap(toUpdate);

      browserStorage.setItem(columnsStoreKey, JSON.stringify(toUpdate));
    },
    [columnsMap],
  );

  const columns = React.useMemo(() => {
    return defaultColumns.map(({id, ...rest}) => ({
      id,
      ...rest,
      visible: typeof columnsMap[id] !== 'boolean' ? defaultColumnsMap[id] : columnsMap[id],
    }));
  }, [columnsMap]);

  return {
    columns,
    toggleColumn,
    resetColumns,
  };
};

export default useServersTableColumns;
