import {Box, Grid} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as RamIcon} from '../../../assets/icons/ram.svg';
import {ReactComponent as SpeedIcon} from '../../../assets/icons/speed.svg';
import {ReactComponent as StorageIcon} from '../../../assets/icons/storage.svg';
import CpuOrGpuLogo from '../../../components/CpuOrGpuLogo';
import Typography from '../../../components/UI/Typography';
import {Instance} from '../../../stores/Instance';
import OverviewItem from './OverviewItem';

interface IProps {
  instance: Instance;
}

const Configuration: React.FC<IProps> = ({instance}) => {
  const {t} = useTranslation();
  return (
    <Box sx={{width: '100%'}}>
      <Typography variant="label3" sx={(theme) => ({marginBottom: theme.spacing(3)})}>
        {t('server_info_overview_configuration_title')}
      </Typography>

      <Grid container spacing={2} alignSelf="baseline">
        <Grid item xs={12}>
          <OverviewItem
            value={
              <>
                <div className="border-icon">
                  <CpuOrGpuLogo modelName={instance.plan?.cpu?.model} />
                </div>
                <Typography variant="body2">{instance.plan?.cpu?.model}</Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <OverviewItem
            value={
              <>
                <div className="border-icon">
                  <RamIcon />
                </div>
                <Typography variant="body2">{instance.plan?.ramSizeFormatted}</Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <OverviewItem
            value={
              <>
                <div className="border-icon">
                  <StorageIcon />
                </div>

                <Typography variant="body2">{instance.plan?.storageSizeFormatted}</Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <OverviewItem
            value={
              <>
                <div className="border-icon">
                  <SpeedIcon />
                </div>

                <Typography variant="body2">{instance.plan?.transferFormatted}</Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <OverviewItem
            value={
              <>
                <div className="border-icon">
                  <SpeedIcon />
                </div>

                <Typography variant="body2">{instance.plan?.findImageById(instance.imageId)?.name}</Typography>
              </>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Configuration;
