import {LoadingButton} from '@mui/lab';
import {Box, Button, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getApiChangeDomainNameServersError} from '../../api/getApiError';
import {ca2domains} from '../../api/proto';
import CheckboxField from '../../components/UI/CheckboxField';
import Typography from '../../components/UI/Typography';
import PageContentLayout from '../../layouts/PageContentLayout';
import {useStore} from '../../stores/AppStore';
import {Domain, standardizeNameServers} from '../../stores/Domain';
import {IDNSRecord} from '../../stores/Domain/DnsRecordsStore';
import {equalStringArraysIgnoreOrder, randomLongId} from '../../utils/arrayUtils';
import DomainRecordsTable, {DomainRecordColumn} from './DomainRecordsTable';
import {IDomainRecordsTableRef} from './DomainRecordsTable/DomainRecordsTable';

interface IProps {
  domain: Domain;
}

function buildDnsRecords(nameServers: string[]): IDNSRecord[] {
  return nameServers.map((nameServer) => ({
    type: 'NS',
    id: randomLongId(),
    nsRecord: {
      nameServer,
    },
  }));
}

export const DomainNsRecords: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {notification} = useStore();

  const domainRecordsTableRef = React.useRef<IDomainRecordsTableRef>(null);

  const [loading, setLoading] = React.useState(false);

  const [dnsRecords, setDnsRecords] = React.useState<IDNSRecord[]>(buildDnsRecords(props.domain.nameServers));
  const [isDefaultNameServers, setIsDefaultNameServers] = React.useState<boolean>(
    props.domain.isDefaultNameServers ?? false,
  );

  const columns: DomainRecordColumn[] = [{name: 'nameServer', label: t('domain_ns_records_name_server_label')}];

  const isInitialNsRecords = dnsRecords.every((record) => record.nsRecord?.nameServer === '');

  const isDirty =
    props.domain.isDefaultNameServers !== isDefaultNameServers ||
    !equalStringArraysIgnoreOrder(
      props.domain.nameServers,
      dnsRecords.map((record) => record.nsRecord?.nameServer || ''),
    );

  const handleToggleDefaultNs = () => {
    setIsDefaultNameServers(!isDefaultNameServers);
  };

  const handleAddNewRecord = async (dnsRecord: IDNSRecord) => {
    if (dnsRecord.nsRecord?.nameServer) {
      const isDuplicate = dnsRecords.some((record) => record.nsRecord?.nameServer === dnsRecord.nsRecord?.nameServer);

      if (isDuplicate) {
        domainRecordsTableRef.current?.setNewRecordFormErrors({
          nameServer: t('domain_ns_records_duplicate_error_message'),
        });

        return;
      }

      setDnsRecords([...dnsRecords, {...dnsRecord, id: randomLongId()}]);
      domainRecordsTableRef.current?.hideRecordForm();
    }
  };

  const handleUpdateRecord = async (dnsRecord: IDNSRecord) => {
    if (dnsRecord.id) {
      setDnsRecords(dnsRecords.map((record) => (dnsRecord.id && record.id?.equals(dnsRecord.id) ? dnsRecord : record)));

      domainRecordsTableRef.current?.resetForm(dnsRecord.id.toString());
    }
  };

  const handleDeleteRecord = async (dnsRecord: IDNSRecord) => {
    setDnsRecords(dnsRecords.filter((record) => record.id && dnsRecord.id?.notEquals(record.id)));
  };

  const handleClickResetButton = () => {
    setDnsRecords(buildDnsRecords(props.domain.nameServers));
    domainRecordsTableRef.current?.resetAllForms();
  };

  const handleClickSaveButton = async () => {
    setLoading(true);

    const {res, error} = await props.domain.changeDomainNameServers(
      dnsRecords.map((record) => record.nsRecord?.nameServer || ''),
      isDefaultNameServers,
    );

    if (error) {
      notification.error(error.message);
      setLoading(false);
      return;
    }

    if (res) {
      const {errors, domain} = res;

      if (errors?.length) {
        errors.forEach((error) => {
          const errorMessage = getApiChangeDomainNameServersError(error);

          if (error === ca2domains.ChangeDomainNameServersError.CDNSE_INVALID_NAME_SERVERS) {
            for (const {id} of dnsRecords) {
              if (id) {
                domainRecordsTableRef.current?.setFormErrors(id?.toString(), {nameServer: errorMessage});
              }
            }
          } else {
            notification.error(errorMessage);
          }
        });

        setLoading(false);
        return;
      }

      if (domain?.nameServers) {
        setDnsRecords(buildDnsRecords(standardizeNameServers(domain.nameServers)));
      }

      notification.success(
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            {t('domain_ns_records_update_success_message')}
          </Typography>
          <Typography variant="body2" color="body.secondary">
            {t('domain_ns_records_update_success_message_text')}
          </Typography>
        </Box>,
      );

      domainRecordsTableRef.current?.resetAllForms();
    }

    setLoading(false);
  };

  return (
    <PageContentLayout sx={{padding: theme.spacing(4)}}>
      <Typography variant="label2" sx={{marginBottom: theme.spacing(2)}}>
        {t('domain_ns_records_title')}
      </Typography>

      <CheckboxField
        label={t('domain_ns_records_form_use_providers_ns_label')}
        FormLabelProps={{
          sx: {
            marginBottom: theme.spacing(2),
            marginLeft: '0px',
            '& .MuiCheckbox-root': {
              paddingLeft: '0px',
            },
          },
        }}
        onChange={handleToggleDefaultNs}
        checked={isDefaultNameServers}
      />

      {isDefaultNameServers ? null : (
        <DomainRecordsTable
          ref={domainRecordsTableRef}
          columns={columns}
          recordType="NS"
          recordKey="nsRecord"
          dnsRecords={dnsRecords}
          addRecordButtonText={t('domain_ns_records_add_new_record_button')}
          onAddNewRecord={handleAddNewRecord}
          onDeleteRecord={handleDeleteRecord}
          onUpdateRecord={handleUpdateRecord}
          readOnly={isDefaultNameServers}
          isInitialNsRecords={isInitialNsRecords}
        />
      )}

      {isDirty ? (
        <Box sx={{marginTop: theme.spacing(4)}}>
          <LoadingButton
            sx={{marginRight: theme.spacing(2)}}
            loading={loading}
            variant="contained"
            size="large"
            onClick={handleClickSaveButton}
          >
            {t('action_button_submit')}
          </LoadingButton>
          <Button variant="outlined" size="large" onClick={handleClickResetButton}>
            {t('action_button_cancel')}
          </Button>
        </Box>
      ) : null}
    </PageContentLayout>
  );
});

export default DomainNsRecords;
